import { useUI } from '@/store/ui';
import { throttle } from '@/utils';
import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Transition } from 'react-transition-group';
import IconArrowLeft from './icon/IconArrowLeft';
import IconArrowRight from './icon/IconArrowRight';
import IconList from './icon/IconList';
import IconFavicon from './icon/IconFavicon';
import { ArtworkColors, ArtworkColorsReverse, ArtworkItem, ArtworkItemView, ItemCanvasLabel } from '@/data';
import IconPlay from './icon/IconPlay';

interface Props {
  list: ArtworkItemView[];
  activeItem: ArtworkItem;
  prevItem: ArtworkItem;
  nextItem: ArtworkItem;
  activeIndex: number;
  onActiveItem: (item: ArtworkItem) => void;
  onClickMenu: () => void;
  isMainCover: boolean;
  videoPercent: number;
  setVideoPercent: (videoPercent: number) => void;
  isHideArrows?: boolean;
  isHideNoise?: boolean;
  isVideoPlay: boolean;
  isPreventHideControls: boolean;
  onChangeCanvas: (canvasLabelIndex: number) => void;
  activeCanvasLabel: ItemCanvasLabel;
  isGalleryType: boolean;
  onVideoPlay: () => void;
  onPageMove: () => void;
  isShowDetail?: boolean;
}

let timeout: ReturnType<typeof setTimeout>;
const TimeoutDuration = 3000;

const CoverControls: React.FC<Props> = ({
  list,
  onActiveItem,
  activeItem,
  prevItem,
  nextItem,
  activeIndex,
  videoPercent,
  onClickMenu,
  isHideArrows,
  isMainCover,
  isHideNoise,
  isVideoPlay,
  isPreventHideControls,
  onChangeCanvas,
  activeCanvasLabel,
  setVideoPercent,
  isGalleryType,
  onVideoPlay,
  onPageMove,
  isShowDetail,
}) => {
  const {
    isActiveAction,
    showActiveAction,
    hideActiveAction,
    activeCanvasLabelIndex: activeCanvasIndex,
    setGalleryIndex,
    activeGalleryIndex,
  } = useUI();
  const [isShowArrowTooltip, setShowArrowTooltip] = useState(true);
  const [isShowPlayArrow, setShowArrow] = useState(true);

  const handleClickItem = useCallback(
    (item: ArtworkItem) => (e: React.MouseEvent) => {
      e.preventDefault();
      onActiveItem(item);
      onPageMove();
    },
    [onActiveItem, onPageMove],
  );

  useEffect(() => {
    const handleMouseMove = throttle(() => {
      setShowArrowTooltip(true);
      showActiveAction();
      clearTimeout(timeout);
      if (isPreventHideControls) {
        showActiveAction();
        return;
      }
      timeout = setTimeout(() => {
        hideActiveAction();
      }, TimeoutDuration);
    });

    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [showActiveAction, hideActiveAction, isPreventHideControls]);

  useEffect(() => {
    if (isPreventHideControls) {
      clearTimeout(timeout);
    }
  }, [isPreventHideControls]);

  const isNoiseBlue = useMemo(() => {
    const isActive = activeItem.canvas.labels[activeCanvasIndex].color === ArtworkColors.blue;
    if (isGalleryType) return activeGalleryIndex === -1 ? isActive : false;
    if (isVideoPlay) return false;
    return isActive;
  }, [activeItem, activeCanvasIndex, isVideoPlay, isGalleryType, activeGalleryIndex]);
  const isNoiseOrange = useMemo(() => {
    const isActive = activeItem.canvas.labels[activeCanvasIndex].color === ArtworkColors.orange;
    if (isGalleryType) return activeGalleryIndex === -1 ? isActive : false;
    if (isVideoPlay) return false;
    return isActive;
  }, [activeItem, activeCanvasIndex, isVideoPlay, isGalleryType, activeGalleryIndex]);
  const isNoiseWhite = useMemo(() => {
    const isActive = activeItem.canvas.labels[activeCanvasIndex].color === ArtworkColors.white;
    if (isGalleryType) return activeGalleryIndex === -1 ? isActive : false;
    if (isVideoPlay) return false;
    return isActive;
  }, [activeItem, activeCanvasIndex, isVideoPlay, isGalleryType, activeGalleryIndex]);

  const isShowPlay = useMemo(() => {
    if (isGalleryType) {
      return activeGalleryIndex === -1;
    }
    if (activeItem.video) {
      return !isVideoPlay;
    }
    return false;
  }, [isGalleryType, activeGalleryIndex, activeItem, isVideoPlay]);

  const handleMovePrev = useCallback(() => {
    onActiveItem(prevItem);
    onPageMove();
    setShowArrowTooltip(false);
  }, [prevItem, onActiveItem, onPageMove]);

  const handleMoveNext = useCallback(() => {
    onActiveItem(nextItem);
    onPageMove();
    setShowArrowTooltip(false);
  }, [onActiveItem, nextItem, onPageMove]);

  const handleClickCanvasLabel = useCallback(
    (index: number) => (e: React.MouseEvent) => {
      e.preventDefault();
      onChangeCanvas(index);
    },
    [onChangeCanvas],
  );

  const handleGalleryPercent = useCallback(
    (index: number) => {
      const len = activeItem.image?.imageList?.length || 0;
      const percent = Math.round(((index + 1) / len) * 100) || 0;
      setVideoPercent(percent);
    },
    [activeItem, setVideoPercent],
  );

  const handleClickThumbnail = useCallback(
    (index: number) => (e: React.MouseEvent) => {
      e.preventDefault();
      if (!isGalleryType) return;
      setGalleryIndex(index);
      handleGalleryPercent(index);
    },
    [setGalleryIndex, isGalleryType, handleGalleryPercent],
  );

  const handleClickPlay = useCallback(() => {
    if (activeItem.video !== null) {
      onVideoPlay();
    } else if (activeItem.image !== null) {
      setGalleryIndex(0);
      handleGalleryPercent(0);
    }
    setShowArrow(false);
  }, [onVideoPlay, activeItem, setGalleryIndex, handleGalleryPercent]);

  return (
    <>
      <Transition timeout={1000} in={isActiveAction}>
        {(state) => {
          const TransitionCommonClassName = 'transition-all duration-500 ';
          const TransitionStyles: { [key: string]: CSSProperties } = {
            entering: { opacity: 1 },
            entered: { opacity: 1 },
            exiting: { opacity: 0 },
            exited: { opacity: 0 },
          };
          return (
            <>
              {/* 메인일 경우 숨김 상태바 숨김 */}
              <div
                className={`${TransitionCommonClassName} z-50 absolute flex left-2 md:left-6 right-2 md:right-6 top-2 ${
                  activeItem.name === null ? 'hidden' : ''
                } ${isMainCover ? 'invible' : ''}`}
                style={TransitionStyles[state]}
                data-type="controls">
                {list.map((item, i) =>
                  i === 0 ? null : (
                    <div
                      key={item.title}
                      className={`py-2 w-full flex relative ${i === list.length - 1 ? '' : 'mr-1'}  cursor-pointer`}
                      style={{ width: item?.percent ? `${item.percent}%` : undefined }}
                      onClick={handleClickItem(item)}
                      data-tip
                      data-for={`loader${i}`}>
                      <div className={`relative w-full ${i < activeIndex ? 'bg-orange' : 'bg-gray-light'} h-1`}>
                        {(isGalleryType ? true : isVideoPlay) && item.title === activeItem.title && videoPercent > 0 && (
                          <span
                            className="absolute top-0 right-0 bottom-0 left-0 bg-orange transition-width duration-200"
                            style={{ width: `${videoPercent}%` }}
                          />
                        )}
                      </div>
                      <ReactTooltip
                        id={`loader${i}`}
                        aria-haspopup="true"
                        textColor="#fff"
                        backgroundColor="#14141480"
                        arrowColor="rgba(0,0,0,0)"
                        className="desktop-only">
                        <p className="text-base">
                          {item.name}, {item.title}
                        </p>
                      </ReactTooltip>
                    </div>
                  ),
                )}
              </div>

              <div
                className={`${TransitionCommonClassName} absolute z-50 flex left-2 md:left-6 right-2 md:right-6 top-6 ${
                  activeItem.name === null ? 'hidden' : ''
                } ${isMainCover ? 'invible' : ''}`}
                style={TransitionStyles[state]}>
                <div className="flex w-full justify-between text-white mt-2">
                  <a href="#main" onClick={handleClickItem(list[0])}>
                    <IconFavicon className="w-10 h-10 lg:w-14 lg:h-14 favicon_rotate" color={activeCanvasLabel.color} />
                  </a>
                  <div className="text-base">
                    {activeIndex} / {list.length - 1}
                  </div>
                </div>
              </div>

              <div className={`${TransitionCommonClassName} ${isHideArrows ? 'invisible' : ''}`} style={TransitionStyles[state]}>
                <button
                  className="arrow absolute -translate-x-1/2 -translate-y-1/2 top-1/2 z-50 left-0 -mt-8"
                  data-tip
                  data-for="arrowLeft"
                  onClick={handleMovePrev}>
                  <IconArrowLeft className="w-10 lg:w-16" color={activeCanvasLabel.color} />
                  {isShowArrowTooltip && (
                    <ReactTooltip
                      id="arrowLeft"
                      aria-haspopup="true"
                      textColor="#fff"
                      backgroundColor="#14141480"
                      arrowColor="rgba(0,0,0,0)"
                      className="desktop-only">
                      <p className="text-base">
                        {prevItem.name}
                        {prevItem.name ? ', ' : ''}
                        {prevItem.title}
                      </p>
                    </ReactTooltip>
                  )}
                </button>
                <button
                  className="arrow absolute -translate-x-1/2 -translate-y-1/2 top-1/2 z-50 right-0 -mt-8"
                  data-tip
                  data-for="arrowRight"
                  onClick={handleMoveNext}>
                  <IconArrowRight className="w-10 lg:w-16" color={activeCanvasLabel.color} />
                  {isShowArrowTooltip && (
                    <ReactTooltip
                      id="arrowRight"
                      aria-haspopup="true"
                      textColor="#fff"
                      backgroundColor="#14141480"
                      arrowColor="rgba(0,0,0,0)"
                      className="desktop-only">
                      <p className="text-base">
                        {nextItem.name}
                        {nextItem.name ? ', ' : ''}
                        {nextItem.title}
                      </p>
                    </ReactTooltip>
                  )}
                </button>
              </div>

              <div className={TransitionCommonClassName} style={TransitionStyles[state]}>
                <Transition timeout={1000} in={isNoiseBlue}>
                  {(state) => {
                    return (
                      <>
                        <div
                          className={`${TransitionCommonClassName} pointer-events-none absolute z-50 bottom-0 w-full bg-no-repeat bg-repeat-x h-screen-1/4 bg-bottom ${
                            isShowDetail ? 'after-blue' : ''
                          } ${isHideNoise ? 'invisible' : ''}`}
                          style={{
                            backgroundImage: `url(/images/noise-blue.png)`,
                            backgroundSize: 'auto 200px',
                            // boxShadow: `rgb(0 161 255) 0px 20rem 0 20rem`,
                            ...TransitionStyles[state],
                          }}
                        />
                      </>
                    );
                  }}
                </Transition>
                <Transition timeout={1000} in={isNoiseOrange}>
                  {(state) => {
                    return (
                      <>
                        <div
                          className={`${TransitionCommonClassName} pointer-events-none absolute z-50 bottom-0 w-full bg-no-repeat bg-repeat-x h-screen-1/4 bg-bottom ${
                            isShowDetail ? 'after-orange' : ''
                          } ${isHideNoise ? 'invisible' : ''}`}
                          style={{
                            backgroundImage: `url(/images/noise-orange.png)`,
                            backgroundSize: 'auto 200px',
                            // boxShadow: `#FF8100 0px 20rem 0 20rem`,
                            ...TransitionStyles[state],
                          }}
                        />
                      </>
                    );
                  }}
                </Transition>
                <Transition timeout={1000} in={isNoiseWhite}>
                  {(state) => {
                    return (
                      <>
                        <div
                          className={`${TransitionCommonClassName} pointer-events-none absolute z-50 bottom-0 w-full bg-no-repeat bg-repeat-x h-screen-1/4 bg-bottom ${
                            isHideNoise ? 'invisible' : ''
                          }`}
                          style={{
                            backgroundImage: `url(/images/noise-white.png)`,
                            backgroundSize: 'auto 200px',
                            boxShadow: `#FFFFFF 0 10rem`,
                            ...TransitionStyles[state],
                          }}
                        />
                      </>
                    );
                  }}
                </Transition>
              </div>

              {activeItem.image && activeItem.image?.imageList && activeGalleryIndex !== -1 && !isShowDetail && (
                <div
                  className={`absolute bottom-24 w-10/12 z-60 flex left-1/2 transform -translate-x-1/2 items-center justify-center mb-4 ${TransitionCommonClassName}`}
                  style={TransitionStyles[state]}
                  data-type="controls-gallery">
                  {isGalleryType &&
                    activeItem.image?.imageList.map((img, i) => {
                      return (
                        <a
                          href={`#thumb${i}`}
                          key={i}
                          className="flex w-18 lg:w-20 mx-0.5 select-none"
                          onClick={handleClickThumbnail(i)}
                          draggable={false}>
                          <img src={img} alt="" className="rounded-md border border-white max-h-12 lg:max-h-full select-none" draggable={false} />
                        </a>
                      );
                    })}
                </div>
              )}

              <div
                className={`absolute left-0 bottom-0 ${isMainCover ? 'w-4/5' : 'w-auto'} z-50 ${TransitionCommonClassName}`}
                style={TransitionStyles[state]}>
                {isMainCover ? (
                  <ul className="text-white flex items-start lg:items-center justify-between px-3 md:px-6 py-5 flex-col lg:flex-row">
                    {activeItem.canvas.labels.map((label, j) => {
                      return (
                        <li key={j} className={`${j === 0 ? 'text-2xl h-8' : ''} pt-1 lg:pt-0 h-5 ${j === 0 ? '' : ''}`}>
                          <a href={`#${label.label}`} onClick={handleClickCanvasLabel(j)}>
                            {label.label}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <div className="text-gray-dark flex flex-col md:flex-row justify-center items-start md:items-center px-3 md:px-6 py-5 pr-28">
                    <span className="text-lg lg:text-xl mr-0 md:mr-4 keep-all">
                      {activeItem.name}, {activeItem.title}
                    </span>
                    <span
                      className="text-xs lg:text-sm font-bold lg:font-black keep-all mt-1 md:mt-0"
                      dangerouslySetInnerHTML={{ __html: activeItem.sub }}
                    />
                  </div>
                )}
              </div>

              {!isShowDetail && isShowPlay && isShowPlayArrow && (
                <div className="absolute right-0 bottom-0 z-50 mb-6 md:mb-6 mr-28 md:mr-32">
                  <span className="py-2 px-3 rounded-md block text-white font-bold relative bubble-body">
                    <span>플레이 눌러서 작품 감상하기</span>
                    <span className="bubble" />
                  </span>
                </div>
              )}

              <div className={`absolute right-0 bottom-0 w-1/5 z-50 ${TransitionCommonClassName}`} style={TransitionStyles[state]}>
                <div className="flex items-center justify-end px-3 md:px-6 py-5">
                  {isShowPlay && (
                    <button className="p-1" onClick={handleClickPlay} data-tip data-for="iconPlay">
                      <IconPlay />
                    </button>
                  )}
                  <button onClick={onClickMenu} className="p-1 ml-2">
                    <IconList color={ArtworkColorsReverse[activeCanvasLabel.color]} />
                  </button>
                </div>
              </div>
            </>
          );
        }}
      </Transition>
    </>
  );
};

export default CoverControls;
