import React, { useCallback, useEffect, useRef, useState } from 'react';
import useParticle from '@/canvas/useParticle';
import { ArtworkItem, ItemCanvasLabel } from '@/data';
import { useUI } from '@/store/ui';

interface Props {
  list: ArtworkItem[];
  activeItem: ArtworkItem;
  onActiveItem: (item: ArtworkItem) => void;
  visible: boolean;
  isVideoPlay: boolean;
  isShowDetail: boolean;
  activeCanvasLabel: ItemCanvasLabel;
  isGalleryType: boolean;
}

let timeout: ReturnType<typeof setInterval>;
let timeoutMouseMove: ReturnType<typeof setTimeout>;

const CoverCanvas: React.FC<Props> = ({ visible, activeItem, isShowDetail, activeCanvasLabel, isVideoPlay, list, isGalleryType }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const canvasRef = useRef<HTMLDivElement>(null);
  const { setActiveLabelIndex, activeGalleryIndex } = useUI();
  const [isLabelChange, setLabelChange] = useState(true);
  const [customValue, setCustomValue] = useState('commoners');

  const { initParticle, pushParticle, hideParticle, drawParticle, updateState, drawParticleCustom, isCanvasInit } = useParticle();

  useEffect(() => {
    window.addEventListener('mousemove', () => {
      clearTimeout(timeoutMouseMove);
      setLabelChange(false);
      timeoutMouseMove = setTimeout(function () {
        setLabelChange(true);
      }, 3000);
    });
    window.addEventListener('mouseleave', () => {
      setLabelChange(true);
    });
  }, []);

  useEffect(() => {
    if (isShowDetail) return clearInterval(timeout);
    const { labels } = activeItem.canvas || {};
    if (labels?.length > 1 && isLabelChange) {
      let labelIndex = labels.findIndex((o) => o.uuid === activeCanvasLabel.uuid) || 0;
      timeout = setInterval(() => {
        labelIndex++;
        let nextLabel = labels[labelIndex];
        if (!nextLabel) labelIndex = 0;
        setActiveLabelIndex(labelIndex);
      }, activeCanvasLabel.switchTime || 3000);
    } else {
      clearInterval(timeout);
    }
    return () => {
      clearInterval(timeout);
    };
    /* eslint-disable-next-line11 */
  }, [activeItem, activeCanvasLabel, setActiveLabelIndex, isShowDetail, isLabelChange]);

  useEffect(() => {
    updateState({ item: activeItem, label: activeCanvasLabel, list });
    initParticle(canvasRef.current);
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    if (!isCanvasInit) return;
    updateState({ item: activeItem, label: activeCanvasLabel });
    drawParticle();
    /* eslint-disable-next-line */
  }, [activeItem, activeCanvasLabel]);

  /**
   * for video type
   */
  useEffect(() => {
    if (!isCanvasInit) return;
    if (isGalleryType) return;
    if (isShowDetail) {
      pushParticle();
    } else {
      if (isVideoPlay) {
        hideParticle();
      } else {
        drawParticle();
      }
    }
    /* eslint-disable-next-line */
  }, [isVideoPlay, isShowDetail, isGalleryType]);

  /**
   * for gallery type
   */
  useEffect(() => {
    if (!isCanvasInit) return;
    if (!isGalleryType) return;
    if (isShowDetail) {
      pushParticle();
    } else {
      if (activeGalleryIndex === -1) {
        drawParticle();
      } else {
        hideParticle();
      }
    }
    /* eslint-disable-next-line */
  }, [activeGalleryIndex, isShowDetail, isGalleryType]);

  const handleChangeCustomInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value.replace(/\s/g, '').length) e.target.value = '';
    setCustomValue(e.target.value.replace(/[^A-Za-z0-9\s]/gi, ''));
  }, []);

  const handleChangeCustomDraw = useCallback(() => {
    drawParticleCustom(customValue === '' ? 'commoners' : customValue);
  }, [drawParticleCustom, customValue]);

  const handleKeyChange = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && inputRef?.current) {
        handleChangeCustomDraw();
        inputRef?.current.blur();
      }
    },
    [inputRef, handleChangeCustomDraw],
  );

  return (
    <div data-type="canvas" className={`absolute top-0 right-0 bottom-0 left-0 z-40 ${visible ? '' : 'pointer-events-none'}`}>
      {!isShowDetail && activeItem.name === '커머너즈' && (
        <>
          <div className="absolute top-24 w-full z-45 md:top-20 md:w-80 md:right-0 md:mr-3">
            <div className="flex border border-white w-11/12 m-auto">
              <input
                ref={inputRef}
                type="text"
                className="appearance-none focus:outline-none border-r border-white px-4 text-white bg-transparent w-full text-bold text-lg md:text-base"
                value={customValue}
                onChange={handleChangeCustomInput}
                onKeyUp={handleKeyChange}
                maxLength={20}
                placeholder="영어 / 숫자"
              />
              <button onClick={handleChangeCustomDraw} className="px-5 py-3">
                <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.2175 5.4043V8.4043L14.8771 4.4043L10.2175 0.404297V3.4043C5.06858 3.4043 0.898193 6.9843 0.898193 11.4043C0.898193 12.9743 1.43405 14.4343 2.34268 15.6643L4.04346 14.2043C3.51925 13.3743 3.22802 12.4143 3.22802 11.4043C3.22802 8.0943 6.36163 5.4043 10.2175 5.4043ZM18.0923 7.1443L16.3915 8.6043C16.9041 9.4443 17.207 10.3943 17.207 11.4043C17.207 14.7143 14.0734 17.4043 10.2175 17.4043V14.4043L5.55784 18.4043L10.2175 22.4043V19.4043C15.3664 19.4043 19.5368 15.8243 19.5368 11.4043C19.5368 9.8343 19.0009 8.3743 18.0923 7.1443Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>
        </>
      )}
      <div ref={canvasRef} className="absolute top-0 right-0 bottom-0 left-0 w-full h-full z-40" />
    </div>
  );
};

export default CoverCanvas;
