import React from 'react';
import Portal from './Portal';
import Dim from './Dim';
import { useUI } from '@/store/ui';

// interface SpinnerProps {
//   active: boolean;
// }

// const Spinner: React.FC<SpinnerProps> = ({ active = false }: SpinnerProps) => {
// if (!active) return null;

export enum SPINNER_EVENTS {
  show = 'show',
  hide = 'show',
}

const Spinner: React.FC = () => {
  const { isShowSpinner } = useUI();

  if (!isShowSpinner) return null;

  return (
    <>
      <Portal>
        <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-50">
          <svg width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <g>
              <animateTransform
                attributeName="transform"
                type="rotate"
                values="0 33 33;270 33 33"
                begin="0s"
                dur="1.4s"
                fill="freeze"
                repeatCount="indefinite"
              />
              <circle fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" strokeDasharray="187" strokeDashoffset="610">
                <animate
                  attributeName="stroke"
                  // values="#d;#DE3E35;#F7C223;#1B9A59;#4285F4"
                  values="#d62d20;#0057e7;#F7C223;#008744;#ffa700"
                  begin="0s"
                  dur="5.6s"
                  fill="freeze"
                  repeatCount="indefinite"
                />
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  values="0 33 33;135 33 33;450 33 33"
                  begin="0s"
                  dur="1.4s"
                  fill="freeze"
                  repeatCount="indefinite"
                />
                <animate attributeName="stroke-dashoffset" values="187;46.75;187" begin="0s" dur="1.4s" fill="freeze" repeatCount="indefinite" />
              </circle>
            </g>
          </svg>
        </div>
      </Portal>
      <Dim active={true} className="z-50" />
    </>
  );
};

export default Spinner;
