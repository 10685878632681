const ContentIntroFooter: React.FC<{ className?: string }> = ({ className = '' }) => {
  return (
    <div className={`flex flex-col w-full px-6 ${className}`}>
      <div className="mt-2 text-sm font-bold opacity-80">
        <p>* 전시 사전예약제와 전자출입명부(QR코드) 인증을 통한 전시관람이 가능합니다.</p>
        <p>* 코로나19 대응단계에 따라 휴관될 수도 있으며, 홈페이지(gmoma.ggcf.kr)에서도 전시를 만나실 수 있습니다.</p>
      </div>
      <div className="mt-4 lg:mt-8 flex items-center justify-start w-full h-20">
        <div className="mr-4 h-inherit">
          <img src="/images/logo-ggc.png" alt="경기문화재단" className="h-auto lg:h-16" />
        </div>
        <div className="mr-12 h-inherit">
          <img src="/images/logo-gga.png" alt="경기도 미술관" className="h-auto lg:h-16" />
        </div>
        <div className="h-inherit">
          <img src="/images/logo-shp.png" alt="삼화페인트" className="h-auto lg:h-16" />
        </div>
      </div>
    </div>
  );
};

export default ContentIntroFooter;
