import short, { SUUID } from 'short-uuid';

export enum ArtworkColors {
  blue = '#00A1FF',
  orange = '#FF8100',
  white = '#FFFFFF',
  gray = '#777777',
}

export const ArtworkColorsReverse: any = {
  [ArtworkColors.blue]: ArtworkColors.orange,
  [ArtworkColors.orange]: ArtworkColors.blue,
  [ArtworkColors.white]: ArtworkColors.orange,
};

export const ArtworkColorsText: any = {
  [ArtworkColors.blue]: ArtworkColors.white,
  [ArtworkColors.orange]: ArtworkColors.white,
  [ArtworkColors.white]: ArtworkColors.gray,
};

export enum ArtworkType {
  video = 'video',
  canvas = 'canvas',
  image = 'image',
}

export interface ItemCanvasLabel {
  label: string | null;
  text: string[];
  color: string; // 파티클, 아래 노이즈 배경 색상
  uuid: SUUID;
  scale: number; // 파티클 크기 옵션
  switchTime?: number; // 자동 넘김 여부, 디자인 상 인트로에서만 사용
}

export interface ItemCanvas {
  labels: ItemCanvasLabel[];
}

interface ItemImage {
  imageList: string[];
}

interface ItemVideo {
  id: number; // 비메오 비디오 ID
  time: number; // 시간(초) 04:30 > 270
}

export interface ArtworkItem {
  name: string | null; // 작가명, null인 경우 메인 커버
  title: string; // 작품명
  sub: string; // 간단 소개
  aboutArtist: string; // 작가 소개
  aboutWork: string; // 작업 소개
  audioUrl: string;

  thumbnail: string; // 비디오 썸네일 이미지
  preview: string; // 비디오 로드 전 blur 이미지

  [ArtworkType.canvas]: ItemCanvas;
  [ArtworkType.video]: ItemVideo | null;
  [ArtworkType.image]: ItemImage | null;

  percent?: number; // 전체 비디오 대비 백분율
}

export interface ArtworkItemView extends ArtworkItem {
  active?: boolean;
  filled?: boolean;
}

export const ArtworkData: ArtworkItem[] = [
  {
    name: null,
    title: '빈지 워칭; 14284"',
    sub: '',
    thumbnail: '#ffffff',
    preview: '#ffffff',
    audioUrl: '/audios/01.mp3',
    canvas: {
      labels: [
        {
          uuid: short.generate(),
          label: '빈지 워칭; 14284"',
          text: ['Binge-', 'watching;', '14284"'],
          color: ArtworkColors.blue,
          scale: 1,
          switchTime: 5000,
        },
        {
          uuid: short.generate(),
          label: '2018-2020 신소장품전',
          text: ['2018-2020', 'New', 'Acquisitions'],
          color: ArtworkColors.orange,
          scale: 0.8,
          switchTime: 4000,
        },
        {
          uuid: short.generate(),
          label: '경기도 미술관',
          text: ['Gyeonggi', 'Museum of', 'Modern Art'],
          color: ArtworkColors.orange,
          scale: 0.8,
          switchTime: 4000,
        },
        {
          uuid: short.generate(),
          label: '2021.7.22 - 10.10',
          text: ['2021.7.22.', '-10.10'],
          color: ArtworkColors.orange,
          scale: 0.925,
          switchTime: 4000,
        },
      ],
    },
    video: null,
    image: null,
    aboutArtist: '',
    aboutWork: '',
  },
  {
    name: '금혜원',
    title: '가족사진',
    sub: '2018, 피그먼트 프린트, ed. 1/5, 구입연도 2019',
    thumbnail: '/covers/금혜원-1.jpeg',
    preview: '/covers/금혜원_thumb.jpeg',
    audioUrl: '/audios/02.mp3',
    canvas: {
      labels: [
        {
          uuid: short.generate(),
          label: null,
          text: ['Keum', 'Hyewon'],
          color: ArtworkColors.white,
          switchTime: undefined,
          scale: 0.85,
        },
      ],
    },
    video: null,
    image: {
      imageList: [
        '/covers/금혜원-1.jpeg',
        '/covers/금혜원-2.jpeg',
        '/covers/금혜원-3.jpeg',
        '/covers/금혜원-4.jpeg',
        '/covers/금혜원-5.jpeg',
        '/covers/금혜원-6.jpeg',
      ],
    },
    aboutArtist: `금혜원(b.1979)은 이화여자대학교 미술학부 졸업 후 동 대학원 한국화과 석사 학위를 받았다. 《on the Blue Territory》(2008, 덕원갤러리)를 시작으로 《섬호광》(2018, 아트스페이스 풀), 《Cloud Shadow Spirit》(2014, 아트선재센터) 등 총 6회의 개인전을 개최했고, 코리아나 미술관(2019), 일민미술관(2018), 서울시립미술관(2017), 한미사진미술관(2016) 등의 여러 단체전에 참여하였다. 2013년 제 12회 다음작가상을 수상했다.`,
    aboutWork: `〈가족사진〉은 작가의 외할머니가 유품으로 남긴 여섯 권의 노트에서 시작되었다. 손 글씨로 정갈히 써 내려간 할머니의 노트에는 지극히 개인적이면서도, 동시에 인식을 공유하는 우리네 역사가 담겨 있었다. 이를테면 일제 강점기, 광복, 한국 전쟁과 같은 우리 근현대사의 굵직한 사건들이 기록의 골자가 되었다. 작가는 1940년대부터 1970년대까지 촬영된 흑백사진을 바닥에 놓고 재촬영한 후, 사진 속 인물을 모두 지우고 빈 곳의 배경을 조심스레 복원하였다. 가족사진이지만, 어느 누구의 가족도 등장하지 않는다. 특정 인물이 사라진 흔적에서 기억 속 저편에 켜켜이 포개놓았던 가족과의 추억을 떠올리는 건 그다지 어렵지 않다. 이렇듯 〈가족사진〉은 불특정한 우리 모두의 기억 속 사진이 된다. 할머니의 일생이 곧 우리의 뼈아픈 근현대사가 된 것처럼, 텅 빈 인물의 자리에는 지나간 우리 가족의 삶이 분명히 존재한다.`,
  },
  {
    name: '김아영',
    title: '다공성 계곡, 이동식 구멍들',
    sub: '2017, 단채널 비디오, 컬러, 사운드, 21분 20초, ed. 3/5, 구입연도 2019',
    thumbnail: '/covers/김아영_thumb.jpeg',
    preview: '/covers/김아영_thumb.jpeg',
    audioUrl: '/audios/03.mp3',
    canvas: {
      labels: [
        {
          uuid: short.generate(),
          label: null,
          text: ['Kim', 'Ayoung'],
          color: ArtworkColors.white,
          switchTime: undefined,
          scale: 0.85,
        },
      ],
    },
    video: {
      id: 575228392,
      time: 68,
    },
    image: null,
    aboutArtist: `김아영(b.1979)은 국민대학교와 런던 컬리지 오브 커뮤니케이션 졸업 후, 첼시 컬리지 오브 아트에서 석사 학위를 취득하였다. 최근 《우각호 시간》(2021, 비데오브라질)에서 온라인 스크리닝을 통해 대표작 7점을 선보였고, 《다공성 계곡》(2018, 일민미술관) 등의 개인전을 개최하였으며, 국내외 다수의 단체전에 참여하였다. 2019년 국립현대미술관 ‘올해의 작가상 2019’ 후원작가로 선정 및 헤콜레 센터(2017, 파리), 삼성 시테아뜰리에(2016, 파리)를 비롯한 다수의 레지던시에 참여했다.`,
    aboutWork: `〈다공성 계곡, 이동식 구멍들〉은 작가의 ‘다공성 계곡’ 시리즈의 첫 번째 작품이다. 이주는 데이터의 단순한 이송이나 정보 재배치, 정보 플랫폼의 변화에 적응하는 과정을 뜻하기도 하고 가로지르기, 플랫폼(터전)을 바꾸는 것을 일컫는다. 작가는 주인공 페트라가 겪는 일종의 이주 과정에 빗대어 오늘날 지구촌 곳곳에서 일어나는 이주와 난민의 문제를 다룬다. ‘다공성 계곡’, 또는 ‘이동식 구멍’이라는 것은 자유롭게 이 세계와 저 세계를 오가기 위한 인식론적 기술이나 수단을 의미한다고 할 수 있다. ‘다공성’이라고 하는 구멍과 균열은 우리의 존재나 의식이 다른 세계로 이주할 수 있는 가능성이기도 하다.`,
  },
  {
    name: '김희천',
    title: '홈',
    sub: '2017, 단채널 비디오, 컬러, 사운드, 40분 5초, ed. 1/3, 구입연도 2019',
    thumbnail: '/covers/김희천_thumb.jpeg',
    preview: '/covers/김희천-1.jpeg',
    audioUrl: '/audios/04.mp3',
    canvas: {
      labels: [
        {
          uuid: short.generate(),
          label: null,
          text: ['Kim', 'Heecheon'],
          color: ArtworkColors.white,
          switchTime: undefined,
          scale: 0.85,
        },
      ],
    },
    video: null,
    image: {
      imageList: ['/covers/김희천-1.jpeg', '/covers/김희천-2.jpeg', '/covers/김희천-3.jpeg', '/covers/김희천-4.jpeg'],
    },
    aboutArtist: `김희천(b.1989)은 한국예술종합학교 건축과를 졸업하였으며, 《탱크》(2019, 아트선재센터), 《홈》(2017, 두산갤러리), 《랠리》(2015, 커먼센터) 등의 개인전을 선보였고, 서울시립 북서울미술관(2021), 아뜰리에 에르메스(2020), 백남준아트센터(2018), 국립현대미술관(2016) 등의 국내외 다수의 단체전에 참여하였다. 2019년 제13회 카이로 비엔날레에서 비엔날레 상을 받았고, 금천예술공장(2021), 두산레지던시 뉴욕(2018) 등에서 입주작가로 활동하였다.`,
    aboutWork: `〈홈〉은 소녀탐정 에리카가 주인공인 가상 애니메이션 〈호-무〉의 ‘덕후’인 화자가 에리카의 흔적을 추적하며 애니메이션의 배경이었던 서울 곳곳을 ‘성지순례’하는 여정을 담고 있다. 성지순례의 장소들은 한국 현대사회의 상징성과 장소로서의 의미를 갖는다. 각 장소에서 일어나고 있는 사건이나 사람들의 모습, 행위 등은 우리 시대의 현재 상황이며 동시에 살아있는 기록이다. 작가는 디지털 기술이 구현하는 가상의 공간과 시간, 즉 ‘사각형 영역’으로 납작하게 압축된 시공간이 수없이 많은 ‘층위’를 형성하게 되는 현상에 주목한다. 그리고 여러 층위의 시공간 안에서 무뎌진 우리의 감각과 인식의 작동방식을 감각하게 한다.`,
  },
  {
    name: '박광수',
    title: '검은 숲 속',
    sub: '2015, 캔버스에 아크릴릭, 290×197㎝, 구입연도 2019',
    thumbnail: '/covers/박광수_thumb.jpeg',
    preview: '/covers/박광수.jpeg',
    audioUrl: '/audios/05.mp3',
    canvas: {
      labels: [
        {
          uuid: short.generate(),
          label: null,
          text: ['Park', 'Gwangsoo'],
          color: ArtworkColors.white,
          switchTime: undefined,
          scale: 0.85,
        },
      ],
    },
    video: null,
    image: {
      imageList: ['/covers/박광수.jpeg'],
    },
    aboutArtist: `박광수(b.1984)는 서울과학기술대학교 조형예술과 졸업 후 동 대학원에서 석사 학위를 받았다. 개인전 《크래커》(2021, 카다로그), 《영영 없으리》(2019, 학고재 갤러리), 《부스러진》(2017, 두산갤러리 서울), 《좀 더 어두운 숲》(2016, 금호미술관) 등을 열었고, 탈영역우정국(2019), 경기도미술관(2019) 등에서 열린 다수의 단체전에도 참여했다. 2016년 제7회 두산연강예술상과 제5회 종근당예술상을 수상하였다.`,
    aboutWork: `〈검은 숲 속〉은 작가가 꾸준히 탐구해 온 ‘소멸’과 ‘종말’의 주제를 관통하는 작품 중 하나이다. 검은 선들이 농도만 달리한 채 쌓여 풍성한 숲이 되었다. 박광수는 빼곡한 나무들, 경계선 없는 어둠과 파편화된 공간을 섬세하면서도 강렬한 선들을 통해 생명력과 이질적인 기억의 범위들을 나타낸다. 숲은 작가에게 원초적인 생명력이 꿈틀대는 장소이자 꿈과 현실이 뒤섞여 예측하기 힘든 공간이다. 때론 아름답기도, 두렵기도 한 공간이 된 것이다. 작가는 그림을 그린다는 것은 어두운 숲을 헤매는 것과 비슷하다고 말한다. 대상을 포획하려 하지만 계속 진동하며 움직이기에, 명확하지 않은 실체를 더듬거리며 매순간 다르게 인지하고 다음 발이 놓일 곳에 집중할 뿐이다. 작가는 생각을 현실로 끌어오는 첫 순간에 ‘드로잉’이라는 장르를 떠올린다. 단순한 재료를 가지고 생각을 이미지화 해내는 드로잉은 하나의 작품, 또는 과정으로서 존재하는 유연함 때문에 화면 위에 ‘칠하기’가 아닌 ‘그리기’로 채워나간다. `,
  },
  {
    name: '박승원',
    title: '대화',
    sub: '2017, 단채널 비디오, 컬러, 사운드, 4분 27초, ed. 1/5, 구입연도 2019',
    thumbnail: '/covers/박승원_thumb.jpeg',
    preview: '/covers/박승원_thumb.jpeg',
    audioUrl: '/audios/06.mp3',
    canvas: {
      labels: [
        {
          uuid: short.generate(),
          label: null,
          text: ['Park', 'Seungwon'],
          color: ArtworkColors.white,
          switchTime: undefined,
          scale: 0.85,
        },
      ],
    },
    video: {
      id: 571515683,
      time: 268,
    },
    image: null,
    aboutArtist: `박승원(b.1980)은 서울대학교 서양화과와 독일 함부르크 조형예술대학을 졸업했다. 2012년 개인전 《Good Morning Miss Lilli!》(2012, 복합문화공간 꿀&꿀풀) 이후 《어느 학술원에의 보고》(2019, 통의동 보안여관), 《유연한 몸부림》(2016, 아마도 예술공간) 등의 개인전을 개최했다. 백남준아트센터(2021) 두산갤러리(2018), 국립현대미술관(2016)을 비롯한 국내외 다수의 단체전에서 작품을 선보였으며, 국립현대미술관 고양레지던시(2015), 서울시립미술관 난지미술창작스튜디오(2014) 등의 레지던시 프로그램에 참여했다.`,
    aboutWork: `〈대화〉는 작가의 신체를 이용한 퍼포먼스를 비디오로 촬영한 것이다. 제자리 뛰기라는 단순한 동작을 반복적으로 수행하는 이 퍼포먼스는 사회의 제도적, 구조적 기호가 가진 권위에서 벗어나고자 발버둥치는 개인의 욕망과 좌절을 가시적으로 보여준다. 단순하지만 강렬한 행위의 반복을 통해 작가는 인간 신체의 한계와 ‘인간다운 삶’의 모습에 대해 진지한 질문을 던진다. 작가는 2010년대부터 독일 유학시절의 경험을 바탕으로 ‘신체’의 다양한 움직임과 존재를 사회 속에서 확인하거나, 사회 속 개인의 존재에 대해 질문하는 작업들을 해왔다. 이후로도 지속적인 퍼포먼스를 통해 신체의 수행성을 탐구하며 자아와의 소통을 시도하는 과정으로 관객들과 소통하려 한다.`,
  },
  {
    name: '배종헌',
    title: '기후의 원천_콜로세움',
    sub: '2010, 319개의 나무상자, 발견된 오브제, 비디오, 가변크기, ed. 1/2(비디오), 구입연도 2018',
    thumbnail: '/covers/배종헌_thumb.jpeg',
    preview: '/covers/배종헌_thumb.jpeg',
    audioUrl: '/audios/07.mp3',
    canvas: {
      labels: [
        {
          uuid: short.generate(),
          label: null,
          text: ['Bae', 'Jongheon'],
          color: ArtworkColors.white,
          switchTime: undefined,
          scale: 0.85,
        },
      ],
    },
    video: {
      id: 577036692,
      time: 826,
    },
    image: null,
    aboutArtist: `배종헌(b.1969)은 경원대학교(현 가천대학교)와 동 대학원 회화과에서 서양화를 전공하고, 현재 대구에서 작업하고 있다. 주요 개인전으로는 《배종헌: 미장제색 美匠霽色》(2019, 아르코미술관), 《첩첩산중》(2018, 파라다이스 ZIP), 《네상스》(2016, 대구미술관), 《변방으로의 욕망_잡초프로젝트》(2005, 인사미술공간) 등이 있고, 성산아트홀(2018), 경기도미술관(2017), 국립현대미술관(2016), 아틀리에 에르메스(2010), 금호미술관(1998) 등의 단체전에 참여했다.`,
    aboutWork: `〈기후의 원천_콜로세움〉은 2010년 발표한 ‘기후 프로젝트’의 작품 중 하나로, 지구 온난화와 이상기후로 인해 재난적 상황에 직면한 동시대의 전지구적 이슈를 탐구한다. 고대 로마시대의 콜로세움이 연상되도록 쌓아올린 319개의 나무상자 안에는 ‘자연’, ‘친환경’, ‘유기농’, ‘그린’ 등의 단어가 들어간 생활 제품들이 들어있다. 디지털 액자에는 마스크, 모자, 팔토시 등 다양한 햇빛 차단 제품을 착용하고 있는 사람들의 모습을 담은 사진들과, 작가의 주변에서 나타나는 이상기후의 징후들을 촬영한 영상이 함께 재생된다. 작품 속 여러 제품들은 지구 환경 보호, 또는 자연친화적 텍스트와 이미지를 사용하여 경쟁적으로 마케팅에 활용하고 있다. 하지만 작가는 우리가 일상에서 쉽게 쓰고 버리는 사물들이 결국은 이상기후를 유발하는 결정적 원인이며, 이미 재난적 상황이 임박했음을 역설적으로 보여준다.`,
  },
  {
    name: '송성진',
    title: '1평조차(1坪潮差)',
    sub: '2018, 단채널 비디오, 컬러, 사운드, 25분 25초<br> 2018, 피그먼트 프린트, 65×98㎝(×6), ed. 1/5, 구입연도 2019',
    thumbnail: '/covers/송성진_thumb.jpeg',
    preview: '/covers/송성진_thumb.jpeg',
    audioUrl: '/audios/08.mp3',
    canvas: {
      labels: [
        {
          uuid: short.generate(),
          label: null,
          text: ['Song', 'Sungjin'],
          color: ArtworkColors.white,
          switchTime: undefined,
          scale: 0.85,
        },
      ],
    },
    video: {
      id: 575844403,
      time: 1543,
    },
    image: null,
    aboutArtist: `송성진(b.1974)은 부산대학교 미술학과 졸업 후 동 대학원 석사와 박사 과정을 마쳤다. 2003년에 진행된 《용호농장》(2003, 두아트갤러리)를 시작으로 《동작들》(2019, 스페이스 mm) 등 총 15회의 개인전을 개최했다. 국립현대미술관(2019), 부산시립미술관(2013), 대만 가오슝시립미술관(2012) 등 국내외에서 100여 회의 단체전에 참여하였다. 2013년 부산비엔날레 바다미술제 공모에서 특선, 2012년 하정웅 청년작가상 등을 수상했다.`,
    aboutWork: `2018년 7월 9일 새벽 5시, 송성진은 안산 대부도 선감선착장 해안가에서 300미터쯤 떨어진 갯벌에 한 평짜리 판잣집을 지었다. 딱 두 달 간만 허가받은 시한부 집이다. 갯벌에 위태로이 지은 집은 두 번이나 썰물과 함께 떠내려갔다. 작품 〈1평조차(1坪潮差)〉는 갯벌에 집을 짓고, 난파된 집을 인양하고, 또 바닷물에 잠기고 드러내기를 반복했던 한 평 집의 63일간의 기록이다. 방글라데시 로힝야 난민촌을 다녀온 작가는 그곳에서 마주한 난민들의 불안한 삶, 임시로 지어진 허름한 가옥, 나약해 보이는 난민선 등에서 작품의 모티브를 얻었다고 한다. 로힝야 난민촌은 잦은 홍수로 사람이 살 수 없는 지역이지만 갈 곳이 없는 난민들이 그나마 얼마간 머물 수 있는 유일한 곳이다. 갯벌에 세운 한 평 집은 살던 곳에서 쫓겨나 떠도는 난민, 이주민들의 불안한 삶과 공간을 연상케 한다. 작가는 ‘평(坪)’이라는 면적 단위를 기호이자 재료로 삼아 ‘이 집은 왜 여기에 있는가, 이 집은 어디로 가야하는가’라는 막막한 질문을 던진다.`,
  },
  {
    name: '심래정',
    title: 'B동 301호',
    sub: '2019, 핸드 드로잉 애니메이션, 3채널 비디오, 흑백, 무음, 56초, 44초, 2분 11초, ed. 1/3, 구입연도 2019',
    thumbnail: '/covers/심래정_thumb.jpeg',
    preview: '/covers/심래정_thumb.jpeg',
    audioUrl: '/audios/09.mp3',
    canvas: {
      labels: [
        {
          uuid: short.generate(),
          label: null,
          text: ['Sim', 'Raejung'],
          color: ArtworkColors.white,
          switchTime: undefined,
          scale: 0.85,
        },
      ],
    },
    video: {
      id: 575844167,
      time: 110,
    },
    image: null,
    aboutArtist: `심래정(b.1983)은 서울과학기술대학교 조형예술과 졸업 후 동 대학원을 졸업했다. 《B동 301호》(2019, 아라리오뮤지엄), 《식인왕국》(2016, 아트스페이스 휴) 등에서 개인전을 개최했으며, 소제동아트벨트(2020), 교보아트스페이스(2019), 사비나미술관(2016), 삼성미술관 리움(2014), 금호미술관(2012), 성곡미술관(2010) 등 다수의 단체전에 참여했다.`,
    aboutWork: `'B동 301호'는 작가가 살고 있는 집주소다. 이 작품은 흰 배경에 검은 선들로 거칠게 그려낸 만화적 이미지를 애니메이션 영상으로 보여준다. 일반인의 접근이 차단된 수술실에서 벌어지는 상황과 그에 따른 감정에 대한 이야기를 구성한 작품이다. 즉, 육체 그 자체를 오롯이 들여다보는 속도감 있는 애니메이션을 통해 신체를 봉합하고 연결하는 수술과정을 작가만의 상상력으로 보여줌으로써 그로부터 드러나는 몸의 반응들을 관찰하고 묘사하고 있는 것이다. 가능하면 드러내고 싶지 않은 우리 삶의 솔직하고 본능적인 부분에 대해 밑바닥 끝까지 이 작품은 들여다보라고 외치는 것 같다. 공포와 예측불가의 연속인 우리 삶은 그렇기 때문에 역설적으로 미지의 세계를 여행하는 흥미로움과 때로 우리를 미소 짓게 하는 반전을 선사하기도 한다. 수술실에서 해체된 신체가 봉합을 마침으로써 비로소 생명이 다시 꿈틀거림을 시작할 수 있듯이, 그녀의 사유과정을 보여주는 이 애니메이션은 우리가 우리 자신을 바라보는 ‘행위의 중첩’으로 말미암아 감정의 불편함을 넘어 어떤 치유까지도 기대하게 만든다.`,
  },
  {
    name: '안정주',
    title: '사이렌',
    sub: '2017, 3채널 비디오, 컬러, 사운드, 11분 18초, ed. 2/5, 구입연도 2019',
    thumbnail: '/covers/안정주_thumb.jpeg',
    preview: '/covers/안정주_thumb.jpeg',
    audioUrl: '/audios/10.mp3',
    canvas: {
      labels: [
        {
          uuid: short.generate(),
          label: null,
          text: ['An', 'Jungju'],
          color: ArtworkColors.white,
          switchTime: undefined,
          scale: 0.85,
        },
      ],
    },
    video: {
      id: 575228944,
      time: 70,
    },
    image: null,
    aboutArtist: `안정주(b.1979)는 서울대학교 미술대학에서 동양화를 전공하고, 연세대학교 커뮤니케이션 대학원에서 미디어아트 석사를 졸업했다. 《Loop》(2016, 두산갤러리 뉴욕), 《정직한 사람》(2012, 프로젝트 스페이스 사루비아), 《Turn Turn Turn》(2007, 금호미술관) 등의 개인전을 개최했으며, 국립현대미술관(2018), 송은아트센터(2017), 아르코미술관(2016), 아뜰리에 에르메스(2015), 원앤제이갤러리(2012), 서울시립미술관(2009), 인사미술공간(2006) 등에서 열린 단체전을 비롯한 다수의 레지던시에 참여했다.`,
    aboutWork: `〈사이렌〉은 길 위에서 작가가 마주했던 일정한 음높이의 경보장치와 안전 유도 마네킹의 움직임을 담아낸다. 신호 및 경보에 주로 사용되는 ‘사이렌’의 이름은 반인반조의 ‘세이렌’이라는 바다의 정령이 아름다운 노랫소리로 뱃사람들을 유혹해 배를 난파시킨다는 그리스 신화에 근원을 두고 있다. 작품에서 흘러나오는 사운드는 교통 유도 마네킹의 움직임에 따라 아날로그 신시사이저를 이용하여 협연하는 형태로 제시되며, 통제에 관한 기계적 신호, 신호의 움직임, 전자적 사운드와 리듬의 반복적인 요소를 드러낸다. 일상 곳곳에 반복되는 위험과 위기에 무뎌진 오늘날, 작품은 사이렌이 가진 사운드의 위력을 상징하고 은유하는 동시에 편집증적이고 불안정한 도시의 감각을 드러낸다.`,
  },
  {
    name: '이우성',
    title: '세상은 내가 꿈꾸지 않게 한다',
    sub: '2014, 천에 아크릴릭, 과슈, 166×300㎝(×2), 구입연도 2019',
    thumbnail: '/covers/이우성_thumb.jpeg',
    preview: '/covers/이우성.jpg',
    audioUrl: '/audios/11.mp3',
    canvas: {
      labels: [
        {
          uuid: short.generate(),
          label: null,
          text: ['Lee', 'Woosung'],
          color: ArtworkColors.white,
          switchTime: undefined,
          scale: 0.85,
        },
      ],
    },
    video: null,
    image: {
      imageList: ['/covers/이우성.jpg'],
    },
    aboutArtist: `이우성(b.1983)은 홍익대학교 회화를 전공하고, 한국예술종합학교 조형예술과 전문사 과정을 졸업했다. 《어쩌면 우리에게 더 멋진 일이 있을지도 몰라》(2021, 두산갤러리), 《당신을 위해 준비했습니다》(2017, 학고재 갤러리) 등의 개인전을 선보였으며, 부산현대미술관(2021), 아르코미술관(2020) 국립현대미술관 청주(2019), ACC 국립아시아문화전당(2018) 등의 단체전을 비롯한 다수의 레지던시에 참여했다.`,
    aboutWork: `두 폭의 그림에는 수면 위로 반짝이는 물결과 해변가에서 노니는 청년들, 파도를 가르는 대형 선박, 그리고 후드를 깊게 눌러쓴 청년의 뒷모습이 담겨있다. 〈세상은 내가 꿈꾸지 않게 한다〉는 4·16 세월호 참사 이후 더 이상 바다의 아름다움을 있는 그대로 보지 못하게 된 외부 상황에 대해 그리고 있다. 낮은 채도와 푸른 빛이 감도는 흑백의 톤으로 표현된 배를 타고 섬으로 가는 장면과 섬에 도착한 한 무리의 여행객들의 모습에서 정적과 불안감이 느껴진다. 제목에서 알 수 있듯 작가의 시적이고 감상적인 자기 고백이며, 사회·정치적 사건을 마주하는 동시대 청년 세대의 시선을 반영하고 있다.`,
  },
  {
    name: '이은우',
    title: '물건 2',
    sub: '2014, 철, 고무줄, PVC 파이프, 나무, 우레탄 페인트, 92.5×40×40㎝, 구입연도 2018',
    thumbnail: '/covers/이은우_thumb.jpeg',
    preview: '/covers/이은우.jpeg',
    audioUrl: '/audios/12.mp3',
    canvas: {
      labels: [
        {
          uuid: short.generate(),
          label: null,
          text: ['Lee', 'Eunu'],
          color: ArtworkColors.white,
          switchTime: undefined,
          scale: 0.85,
        },
      ],
    },
    video: null,
    image: {
      imageList: ['/covers/이은우.jpeg'],
    },
    aboutArtist: `이은우(b.1982)는 한국예술종합학교 미술원 조형예술과를 졸업 후 동 대학원에서 석사 학위를 받았다. 《사건의 지평선》(2009, 대안공간 루프)을 시작으로 《긴, 납작한, 매달린》(2015, 아트선재 프로젝트 스페이스) 등의 개인전을 개최하였으며, 서울대학교미술관(2018), 경기도미술관(2017), 서울시립 북서울미술관(2016) 등의 단체전에 참여했다. 2016년 제16회 송은예술대상 우수상을 받았다.`,
    aboutWork: `〈물건 2〉는 철로 된 판과 판을 60개의 PVC 파이프를 사용해 불안정하게 쌓아올린 후 균형이 흐트러질 것 같은 위치에 오렌지색 원뿔을 거꾸로 끼워 넣어 긴장감을 유지하고 있다. 작품을 구성하고 있는 철판, PVC 파이프, 안전 고깔은 주로 공사장에서 사용되는 산업재료인데, 그 기능을 상실한 채 오직 형태와 색채만 남은 ‘물건’들은 반복, 대칭, 비례와 같은 속성을 지닌 하나의 기하학적 조각 작품으로 존재한다. 작가는 본인의 작업은 무엇을 향한 오마주 또는 이미테이션, 가짜와 진짜, 노스탤지어, 장식, 실용, 버내큘러를 관통하는 어떤 경계선에 위치한다고 말한다. 사물의 관습적인 용법을 포착해 다른 성질의 사물과 결합하는 방식으로 재해석하는 과정을 통해 새롭게 탄생한 작품들은 그것이 ‘작품’인지 ‘물건’인지 그 경계가 모호하다. 그것이 작품인지 물건인지, 유용한지 그렇지 않은지는 그것이 맺고 있는 관계, 즉 그 사물이 속한 문화의 제도와 관습, 제작의도, 생산과 소비의 주체 등에 따라 결정된다.`,
  },
  {
    name: '장서영',
    title: '서클',
    sub: '2017, 단채널 비디오, 컬러, 사운드, 7분 58초, ed. 2/3, 구입연도 2019',
    thumbnail: '/covers/장서영_thumb.jpeg',
    preview: '/covers/장서영_thumb.jpeg',
    audioUrl: '/audios/13.mp3',
    canvas: {
      labels: [
        {
          uuid: short.generate(),
          label: null,
          text: ['Chang', 'Seoyoung'],
          color: ArtworkColors.white,
          switchTime: undefined,
          scale: 0.85,
        },
      ],
    },
    video: {
      id: 575229114,
      time: 479,
    },
    image: null,
    aboutArtist: `장서영(b.1983)은 이화여자대학교에서 조소 전공 후 동 대학원과 베를린 예술대학교 석사학위를 취득하였다. 개인전 《시작하자마자끝나기시작》(2019, 두산갤러리), 《무력한 불안》(2010, 스페이스 집 갤러리) 등을 개최하였으며, 경기도미술관(2020), 국립현대미술관(2019), 아르코미술관(2018), 인천아트플랫폼(2017) 등 다수의 단체전 및 레지던시에 참여했다. 2014년 제14회 서울 국제 뉴미디어 페스티벌 뉴미디어아트상, 2011년 제8회 부산 국제 비디오페스티벌 우수상을 받았다.`,
    aboutWork: `〈서클〉은 작가가 그간 지속적으로 탐구해 온 신체와 반복의 키워드를 잘 드러내는 작품 중 하나이다. 작가는 시작, 끝, 반복의 시간성을 가진 영상 매체가 지닌 특징을 활용하여 시작과 끝 혹은 삶과 죽음, 그리고 그 사이 흐르는 시간 속 존재들의 결함과 유한함을 이야기한다. 끝과 시작을 알 수 없는 모호한 시간성 안에서 작가는 신체, 불안, 공허, 종말, 병듦의 시작과 끝 사이에서 출구를 알 수 없는 순간들을 연장시킨다. 장서영은 삶과 죽음의 과정 안에서 한계를 가진 존재에 주목하며 다양한 영상과 설치 작품을 선보이고 있다. 작가는 신체에 대한 집요한 탐구의 끈을 놓지 않으며, 제도의 모호한 경계를 탐색한다. 그의 작품은 육체, 삶, 제도, 세계의 한계 등 신체에서 우리가 존재하고 있는 공간에 이르기까지 관객으로 하여금 유한함을 인지하고 감각하게 한다.`,
  },
  {
    name: '정은영',
    title: '가사들 1, 2, 3',
    sub: '2013, 3채널 비디오, 컬러, 사운드, 2분 39초, 6분 12초, 3분 21초, ed. 1/5, 구입연도 2019',
    thumbnail: '/covers/정은영_thumb.jpeg',
    preview: '/covers/정은영-6.jpeg',
    audioUrl: '/audios/14.mp3',
    canvas: {
      labels: [
        {
          uuid: short.generate(),
          label: null,
          text: ['Jung', 'Eunyoung'],
          color: ArtworkColors.white,
          switchTime: undefined,
          scale: 0.85,
        },
      ],
    },
    video: null,
    image: {
      imageList: [
        '/covers/정은영-1.jpeg',
        '/covers/정은영-2.jpeg',
        '/covers/정은영-3.jpeg',
        '/covers/정은영-4.jpeg',
        '/covers/정은영-5.jpeg',
        '/covers/정은영-6.jpeg',
      ],
    },
    aboutArtist: `정은영(b.1974)은 이화여자대학교와 동 대학원에서 서양화를, 영국 리즈대학교 대학원에서 시각예술과 페미니즘 이론을 공부했다. 《어리석다 할것인가 사내답다 할것인가》(2018, d/p), 《틀린색인: 여성국극 아카이브》(2018, NTU CCA) 등의 개인전을 개최하였으며, 아트바젤 홍콩(2019), 상하이비엔날레(2018), 서울시립 북서울미술관(2017) 등 국내외 다수의 단체전에 및 2019년 베니스 비엔날레 한국관 전시에 참여하였다. 2018년 국립현대미술관 ‘올해의 작가상 2018’, 2015년 제6회 SNIAP 신도리코 미술상 등을 수상했다.`,
    aboutWork: `1950년대 후반은 여성국극의 시대였다. 단체들도 늘고 작품도 다양해졌다. 그러나 1960년대가 되자 TV가 보급되고 영화 제작이 늘면서 쇠락하기 시작했다. 정은영은 지나간 한 시절의 인기 여성국극이 아닌, 파란만장의 격동기에 펼쳐진 이 극의 다층적이고 다성적인 배우들의 내재율에 주목했다. 자신의 목소리를 삼킨 뒤 남성의 목울대로 변신해 무대 위아래를 장악한 배우들, 과장된 분신술로 선악을 결판내고 열애를 퍼트리는 장면들, 환상과 환영의 판타지로 현실의 이면을 뒤집는 리얼리티는 여성 관객들을 해방구로 이끌었다. 작가는 여성국극의 재현된 어떤 장면들, 혹은 재연의 무늬들을 영상으로 담았다. 그 중 〈가사들〉은 노배우들이 배역으로 몰입해 들어가는 역할극의 연습과 재현들이다. 재간꾼(삼마이)을 맡았던 한 배우가 1세대의 묘역에서 소리를 하는 〈가사들 1〉, 노배우와 젊은 배우가 남장의 남성 주인공(니마이) 역할을 학습하는 〈가사들 2〉, 그리고 악역 조연(가다끼)의 장면을 연습하는 〈가사들 3〉으로 구성되어 있다.`,
  },
  {
    name: '정희승',
    title: '무제',
    sub: '2009, 피그먼트 프린트, 148×119㎝, ed. 3/5, 구입연도 2019<br> 2014, 피그먼트 프린트, 225×156㎝, ed. 1/5, 구입연도 2019',
    thumbnail: '/covers/정희승-2.jpeg',
    preview: '/covers/정희승_thumb.jpeg',
    audioUrl: '/audios/15.mp3',
    canvas: {
      labels: [
        {
          uuid: short.generate(),
          label: null,
          text: ['Chung', 'Heeseung'],
          color: ArtworkColors.white,
          switchTime: undefined,
          scale: 0.85,
        },
      ],
    },
    video: null,
    image: {
      imageList: ['/covers/정희승-1.jpeg', '/covers/정희승-2.jpeg'],
    },
    aboutArtist: `정희승(b.1974)은 홍익대학교 회화과 졸업 후 런던 컬리지 오브 커뮤니케이션 사진학과에서 학사 및 석사 학위를 취득했다. 《중간보고서-스탄차》(2017, 고은사진미술관), 《Still-Life》(2012, 두산갤러리 뉴욕) 《Unphotographable》(2011, 두산갤러리 서울) 등에서 개인전을 개최했으며, 하이트컬렉션(2019), 대구문화예술회관(2018), 누크갤러리(2017) 등 다수의 단체전에 참여했다. 2020년 국립현대미술관 ‘올해의 작가상 2020’ 후원작가로 선정되었다.`,
    aboutWork: `〈무제〉는 명확히 규정할 수 없는 상태를 작품으로 다룬 ‘스틸 라이프’(2009~2014) 연작이다. 정희승은 “단일한 개념이나 선적인 내러티브 안에 종속되지 않기에 이 작업을 연작이라고 부르기는 어렵다”라고 밝힌 바 있지만, 같은 범주에 속하는 것은 분명해 보인다. ‘스틸 라이프’ 연작은 일상적인 사물이나 신체의 일부를 집중적으로 발췌하여 촬영한 작품들이다. 〈무제〉에 담긴 대상 또한 한눈에 알아볼 수 있지만, 어딘가 분명치 않으며 작품에는 일말의 긴장감까지 감돈다. 더불어 작품에 담긴 사물과 신체, 즉 생물과 무생물의 경계는 무척이나 모호하다. 이는 작가가 존재의 정형화된 도상을 탈피한 데서 이유를 찾을 수 있다. 이렇듯 작가는 피사체에 있어 비정형적인 찰나의 순간을 사진으로 기록하여 영속성을 부여한다. 여기서 ‘still’의 새로운 쓰임을 발견하게 된다. ‘스틸 라이프’ 연작은 일반적인 ‘정지’가 아닌 ‘지속’의 ‘still’을 내포한다.`,
  },
  {
    name: '진기종',
    title: '염주와 기도',
    sub: '2015, 나무, 폴리우레탄 페인트, 끈, 단채널 비디오, 컬러, 사운드, 가변크기, 11분 44초, 구입연도 2018',
    thumbnail: '/covers/진기종_thumb.jpeg',
    preview: '/covers/진기종_thumb.jpeg',
    audioUrl: '/audios/16.mp3',
    canvas: {
      labels: [
        {
          uuid: short.generate(),
          label: null,
          text: ['Zin', 'Kijong'],
          color: ArtworkColors.white,
          switchTime: undefined,
          scale: 0.85,
        },
      ],
    },
    video: {
      id: 575843768,
      time: 707,
    },
    image: null,
    aboutArtist: `진기종(b.1981)은 경원대학교(현 가천대학교) 환경조각과 졸업 후, 첫 개인전 《On Air》(2008, 아라리오갤러리 서울)를 시작으로 《무신론보고서》(2015, 갤러리현대) 등 총 9회의 개인전을 선보였다. 국립현대미술관(2017), 경기도미술관(2016), 서울시립미술관(2015), 삼성미술관 리움(2013) 등 국내외 다수의 단체전에 참여하였으며, 2018년 제8회 SINAP 신도리코 미술상, 2017년 제17회 송은미술대상 우수상 등을 수상하였다.`,
    aboutWork: `작품은 108개의 구슬이 꿰어진 염주를 쥐고 합장을 하고 있는 커다란 손과 절에서 만난 이금순 할머니의 살아온 인생 이야기를 담은 영상으로 구성되어 있다. 할머니는 불가에 입문한 이래 60여 년간 하루도 빠짐없이 절에 시주를 하고 기도한 덕분에 4남매를 훌륭하게 키웠다고 굳건하게 믿고 있으면서도 “부처는 절에 우상으로 존재하는 것이 아니라 내 마음 속에 있다”고 이야기 한다. 〈염주와 기도〉는 이금순 할머니가 염주를 돌리면서 무엇을 위해 기도하는지에 대해 인터뷰한 영상과 실제 할머니의 손을 상징적인 조각으로 표현한 작품이다. 작가는 신과 종교를 부정하지도, 맹신하지도 않는 중립적인 시각에서 불교뿐만 아니라 천주교, 이슬람교 등 많은 사람들이 믿는 종교의 상징물을 통해 ‘과연 신은 존재할까?’, ‘인간은 왜 신을 믿을까?’ 와 같은 작가의 의구심에 대해 꾸준히 의문을 제기하고 있다.`,
  },
  {
    name: '파트타임스위트',
    title: '나를 기다려, 추락하는 비행선에서',
    sub: '2016, 360° VR 비디오, 컬러, 사운드, 16분 45초, ed. 2/5, 구입연도 2019',
    thumbnail: '/covers/파트타임_thumb.jpeg',
    preview: '/covers/파트타임_thumb.jpeg',
    audioUrl: '/audios/17.mp3',
    canvas: {
      labels: [
        {
          uuid: short.generate(),
          label: null,
          text: ['Part-time', 'Suite'],
          color: ArtworkColors.white,
          switchTime: undefined,
          scale: 0.85,
        },
      ],
    },
    video: {
      id: 571525366,
      time: 1035,
    },
    image: null,
    aboutArtist: `파트타임스위트는 2009년 박재영, 이미연, 이병재 세 명의 멤버로 시작하여 2013년부터는 박재영과 이미연이 듀오로 활동하고 있다. 《에어》(2019, 합정지구), 《부동산의 발라드》(2017, ARTZONE, 교토) 등의 개인전을 선보였고, 국립현대미술관(2019), 아르코미술관(2019), 백남준아트센터(2018), 서울시립미술관(2017), 인사미술공간(2015), 문화역서울 284(2012), 아뜰리에 에르메스(2011), 공간 해밀톤(2010) 등 국내외 다수의 단체전에 참여했다.`,
    aboutWork: `작품은 한국 근대사의 한 부분을 차지하고 있는 은밀하고 폭력적인 정치권력과 이로부터 계획된 도시화의 이면을 폭로한다. 영상 내내 끊임없이 ‘너’로 지칭되어 다중의 자아로 표상되는 파란 존재가 등장한다. 그는 한 때 누군가의 빛나는 미래, 희망이었을지도 모르는 신도시 재개발 지역이지만 실상은 실패한 공간, 주변으로 밀려난 공간, 폐허인 장소로 던져진다. 그는 공사장, 고시원, 지하벙커, 전자 쓰레기 처리장, 광장 등 주변부의 공간들을 유영하다 마침내 이 도시로부터 다시 추락하는 데에 성공한다. 생생히 살아있는 VR(가상현실) 세계 안의 풍경 속에서 그 경험의 주체인 ‘나’의 현존은 ‘자동지움’된 채 투명해지고 만다. 그러나 관객이 공간을 자유롭게 네비게이트하는 VR이 주는 신체적 현실감과 강렬한 사운드는 자유와 회복에 대한 미래적 단서를 제공한다.`,
  },
  {
    name: '함양아',
    title: '정의되지 않은 파노라마 2.0',
    sub: '2019, 단채널 비디오, 컬러, 사운드, 7분 1초, ed. 1/5, 구입연도 2019',
    thumbnail: '/covers/함양아_파노라마_thumb.jpeg',
    preview: '/covers/함양아_파노라마_thumb.jpeg',
    audioUrl: '/audios/18.mp3',
    canvas: {
      labels: [
        {
          uuid: short.generate(),
          label: null,
          text: ['Ham', 'Yangah'],
          color: ArtworkColors.white,
          switchTime: undefined,
          scale: 0.85,
        },
      ],
    },
    video: {
      id: 575229950,
      time: 421,
    },
    image: null,
    aboutArtist: `함양아(b.1968)는 서울대학교 미술대학 서양화과 전공 및 동 대학원에서 미술이론, 뉴욕대학교 미디어아트에서 석사학위를 취득하였다. 《정의되지 않은 파노라마 2.0》(2019, 대안공간 루프),《형용사적 삶〉넌센스 팩토리》(2010, 아트선재센터) 등의 개인전을 개최하였으며, 타이베이 시립미술관(2018), 대만 국립현대미술관(2017), 국립현대미술관(2013) 등 국내외 다수의 단체전과 비엔날레에 참여하였다. 2013년 국립현대미술관 ‘올해의 작가상 2013’과 2008년 ‘에르메스 재단 미술상’에 노미네이트 되었으며, 2004년 제3회 다음작가상을 수상하였다.`,
    aboutWork: `〈정의되지 않은 파노라마 2.0〉은 현대 기술의 발전이 가져온 경제 성장과 세계화는 결국 경제 양극화와 불평등을 심화시키며 개인과 공동체의 삶을 더욱 불안하게 만들었다는 지점에 주목한다. 작품은 인간과 비인간을 비롯해 사회 전반에서 벌어지는 개별적인 개체와 사건들이 복잡하게 상호 연결된 관계망 속에서 어떻게 작동하며 궁극적으로 어떻게 하나의 서사를 그리는지 보여준다. 작가는 작품을 통해 앞으로 나와 우리, 그리고 세계는 어떠한 변화를 맞이하게 될 것인지, 그리고 그 과정에서 우리가 함께 생각하고 추구해야 할 지점은 무엇인지 질문을 던진다.`,
  },
  {
    name: '함양아',
    title: '잠',
    sub: '2015-2016, 2채널 비디오, 컬러, 사운드, 8분 40초, ed. 3/5, 구입연도 2019',
    thumbnail: '/covers/함양아_잠_thumb.jpeg',
    preview: '/covers/함양아_잠_thumb.jpeg',
    audioUrl: '/audios/19.mp3',
    canvas: {
      labels: [
        {
          uuid: short.generate(),
          label: null,
          text: ['Ham', 'Yangah'],
          color: ArtworkColors.white,
          switchTime: undefined,
          scale: 0.85,
        },
      ],
    },
    video: {
      id: 575229719,
      time: 520,
    },
    image: null,
    aboutArtist: `함양아(b.1968)는 서울대학교 미술대학 서양화과 전공 및 동 대학원에서 미술이론, 뉴욕대학교 미디어아트에서 석사학위를 취득하였다. 《정의되지 않은 파노라마 2.0》(2019, 대안공간 루프),《형용사적 삶 넌센스 팩토리》(2010, 아트선재센터) 등의 개인전을 개최하였으며, 타이베이 시립미술관(2018), 대만 국립현대미술관(2017), 국립현대미술관(2013) 등 국내외 다수의 단체전과 비엔날레에 참여하였다. 2013년 국립현대미술관 ‘올해의 작가상 2013’과 2008년 ‘에르메스 재단 미술상’에 노미네이트 되었으며, 2004년 제3회 다음작가상을 수상하였다.`,
    aboutWork: `〈잠〉은 위기와 재난의 상황에서 사회라는 거대한 시스템이 작동하는 방식과 그 안에서의 개인들이 취하는 저마다의 태도와 역할에 주목한다. 작가는 현대 사회의 위기에서 개인들이 느끼는 불안과 두려움, 저마다의 태도와 역할, 그리고 이 위기를 둘러싼 사회 시스템을 은유한다. 작품 앞에서 관객은 잠이 든 자들을 바라보며 깨어 있는 자로, 이 모든 상황을 지켜보는 자의 위치에 놓인다. 재난과 위기의 상황에서 결코 편치 않은 잠을 청하며 무방비하게 노출된 사람들과 이를 바라보며 깨어 있는 사람들, 그 위로 흐르는 불안과 두려움을 당신은 어떻게 바라보고 있는지, 어떻게 바라볼 것인지 한 번 더 질문한다.`,
  },
  {
    name: '커머너즈',
    title: '14284',
    sub: '2021, 디지털 아트워크, 경기도미술관 제작지원',
    thumbnail: '/covers/black.jpg',
    preview: '/covers/커머너즈.jpg',
    audioUrl: '/audios/20.mp3',
    canvas: {
      labels: [
        {
          uuid: short.generate(),
          label: null,
          text: ['commoners'],
          color: ArtworkColors.white,
          switchTime: undefined,
          scale: 0.85,
        },
      ],
    },
    video: null,
    image: null,
    aboutArtist: `커머너즈는 서울을 기반으로 활동하는 디지털 디자인 스튜디오로 관객 참여적 예술을 지향하며, 웹 개발 및 시각예술 분야에서 활동하고 있다. 다양한 실험과 프로젝트에 참여하면서 아직 발견되지 않은 동시대적 요구를 맞닥뜨리고, 탐색하고자 한다.`,
    aboutWork: `〈14284〉는 경기도미술관이 지난 3년간 수집한 소장품을 공개하는 전시 《빈지 워칭; 14284″》를 위해 만드는 디지털 아트워크이자 온라인 전시 감상 플랫폼이다. 숫자와 알파벳으로 이루어진 총 14,284개의 파티클이 관객의 움직임에 따라 화면 안팎으로 이리저리 흩어지고 모이며 다양한 단어를 형상화한다. 커머너즈는 각각의 주제를 가지고 있는 작품들이 한 공간에 모여 하나의 전시를 만들어내는 이번 전시처럼 별개의 요소들이 군집하여 새로운 의미를 만들어낸다는 지점에 주목하여 작품을 구상했다. 파티클은 무한한 형상의 변화과정 속의 순간일 뿐이며, 언제든 다른 형태를 취할 수 있다. 끊임없이 움직이는 14,284개의 파티클은 그 자체로서 관객과 조응하는 감상과 유희의 대상이며, 동시에 한 작품에서 다른 작품에 이르는 교량과도 같다. 작가는 관객이 스스로 작품을 선택하고 감상하는 과정을 통해 자신만의 이야기를 구성하길 제안한다.`,
  },
];
