const IconFavicon: React.FC<{ className?: string; color?: string }> = ({ className = '', color }) => (
  <svg width="60" height="60" fill="none" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg" className={`${className}`}>
    <path d="M59.5 30a29.5 29.5 0 11-59 0 29.5 29.5 0 0159 0z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.9 8.9l1.5-.6 1.3 3.4-1.4.6 1 2.3-4.2 1.7-1-2.4-6.3 2.6-1.6-4L18.6 3l4.2-1.7L26 8.9zm-4 1.6L20.4 7l-1.7 4.8 3.2-1.3zM4.2 14.8l1.9-3 12.2 7.6-2.4 4L9.2 19l-1.8 3L5 20.3 5 20c.6-.7.8-1.7.7-2.6-.3-1-.8-2-1.6-2.6zm39.2 1.5L36.7 13h1.5l2.2.2c2.8.3 4.7-.5 5.7-2.5a4.5 4.5 0 00.1-3.8c-.5-1.3-1.7-2.3-3.5-3.2a6.9 6.9 0 00-4.8-.8 5.7 5.7 0 00-3.6 3l3.9 1.9a2 2 0 011-1 1.8 1.8 0 011.4.2c1 .5 1.4 1.2 1 2a1.3 1.3 0 01-.8.7c-.5.1-1.1.2-1.7 0h-1.6c-1.3-.3-2.7-.1-4 .3-1 .6-2 1.5-2.4 2.7l-.7 1.4 11.3 5.6 1.7-3.4zm9 11a3.4 3.4 0 01-2.5 3.1 3.7 3.7 0 01-3.6-.5 8 8 0 01-2.7-4 8 8 0 01-.3-4.8 3.7 3.7 0 012.4-2.7 3.4 3.4 0 014 1 3.4 3.4 0 012.5-2.6 3.4 3.4 0 013.3.5c1.1 1 2 2.3 2.4 3.7.6 1.4.7 2.9.3 4.3a3.4 3.4 0 01-2.3 2.4 3.4 3.4 0 01-3.5-.5zm-4.8-4.9c-1 .4-1.2 1.1-.8 2.3.4 1.3 1.1 1.7 2 1.4 1-.4 1.3-1.1.9-2.3-.4-1.2-1.1-1.7-2-1.4zm7.4.9c.2-.4.1-1 0-1.3a2 2 0 00-.8-1.2 1.3 1.3 0 00-1.1 0 1.2 1.2 0 00-.9.7 2 2 0 00.1 1.4c.1.4.4.8.8 1a1.2 1.2 0 001.1.2 1.3 1.3 0 00.8-.8zm-8.7 22.1L47 44l7.5 3.4 1.9-4.1-4.2-9.4-3.9-1.7-2.8 6.2-2.3-1-1.8 4 2.3 1L43 44l3.3 1.5zm6-3.8L48.7 40l1.3-3 2.1 4.6z"
      fill={color}
    />
  </svg>
);

export default IconFavicon;
