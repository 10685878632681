import { CSSProperties, useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import Player from '@vimeo/player';
import { useUI } from '@/store/ui';
import { ArtworkItem } from '@/data';

const ID_PLAYER = 'player';

interface PlayerTime {
  seconds: number;
  percent: number;
  duration: number;
}

interface Props {
  activeItem: ArtworkItem;
  nextItem: ArtworkItem;
  onActiveItem: (item: ArtworkItem) => void;
  onUpdateVideoPercent: (percent: number) => void;
  onVideoPlay: () => void;
  onVideoPause: () => void;
  visible: boolean;
}

const CoverVideo: React.FC<Props> = ({ visible, activeItem, nextItem, onActiveItem, onUpdateVideoPercent, onVideoPlay, onVideoPause }) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const { showActiveAction, hideActiveAction, player, setPlayer } = useUI();

  useEffect(() => {
    const { id } = activeItem?.video || {};
    if (id) {
      if (player) {
        player.loadVideo(id).then(() => setVideoLoaded(true));
        setTimeout(() => {
          // timeupdate 옵션의 플레이 아이디 변경 이후 최신 업데이트되는 타이밍 문제로 비동기 초기화 처리
          onUpdateVideoPercent(0);
        }, 300);
      } else {
        setPlayer(
          new Player(ID_PLAYER, {
            autoplay: false,
            controls: true,
            playsinline: true,
            muted: false,
            loop: false,
            responsive: false,
            portrait: false,
            id,
          }),
        );
      }
    } else {
      setPlayer(undefined);
    }
  }, [activeItem, player, onUpdateVideoPercent, setPlayer]);

  useEffect(() => {
    if (player) {
      const onVideoEnded = () => {
        const { id } = nextItem?.video || {};
        onActiveItem(nextItem);
        setVideoLoaded(false);
        if (id) player.loadVideo(id).then(() => setVideoLoaded(true));
      };
      const onVideoTimeUpdate = (time: PlayerTime) => onUpdateVideoPercent(time.percent * 100);
      const onVimeoVideoPause = (time: PlayerTime) => {
        if (!videoLoaded) return;
        if (time.seconds > 1) onVideoPause();
      };
      const onVideoPlaying = () => {
        if (!videoLoaded) return;
        onVideoPlay();
      };
      player.off('playing');
      player.on('playing', onVideoPlaying);
      player.off('pause');
      player.on('pause', onVimeoVideoPause);
      player.off('ended');
      player.on('ended', onVideoEnded);
      player.off('timeupdate');
      player.on('timeupdate', onVideoTimeUpdate);
    }
  }, [player, nextItem, onActiveItem, onUpdateVideoPercent, onVideoPlay, hideActiveAction, onVideoPause, videoLoaded]);

  if (activeItem?.video === null) return null;

  return (
    <Transition timeout={1000} in={visible}>
      {(state) => {
        const TransitionCommonClassName = 'transition-opacity duration-500 ';
        const TransitionStyles: { [key: string]: CSSProperties } = {
          entering: { opacity: 1 },
          entered: { opacity: 1 },
          exiting: { opacity: 0 },
          exited: { opacity: 0 },
        };

        return (
          <>
            <div
              className={`fixed top-0 bottom-0 right-0 left-0 flex justify-center items-center ${TransitionCommonClassName} z-30`}
              style={TransitionStyles[state]}
              data-type="video"
              ref={videoRef}>
              <div className={`w-screen ${TransitionCommonClassName} h-screen max-h-screen-7/10 lg:max-h-screen-9/10`}>
                <div
                  id={ID_PLAYER}
                  className="container mx-auto h-full flex items-center justify-center"
                  onMouseMove={showActiveAction}
                  onMouseEnter={showActiveAction}
                  onMouseLeave={showActiveAction}
                  onTouchMove={showActiveAction}
                />
              </div>
            </div>
            <div
              className={`pointer-events-none absolute top-0 right-0 bottom-0 left-0 bg-black z-20 ${TransitionCommonClassName}`}
              style={TransitionStyles[state]}
            />
          </>
        );
      }}
    </Transition>
  );
};

export default CoverVideo;
