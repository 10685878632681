type Props = {
  color: any;
};

const IconList: React.FC<Props> = ({ color }) => (
  <svg width="26" height="17" viewBox="0 0 26 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="26" height="3" fill={color} />
    <rect y="7" width="26" height="3" fill={color} />
    <rect y="14" width="18" height="3" fill={color} />
  </svg>
);

export default IconList;
