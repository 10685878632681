import React, { useCallback } from 'react';
import ReactTooltip from 'react-tooltip';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import IconDrag from './icon/IconDrag';
import { moveArrayItemToNewIndex } from '@/utils';
import { ArtworkItem, ArtworkColorsText, ArtworkColorsReverse } from '@/data';
import { useUI } from '@/store/ui';
import IconPlay from './icon/IconPlay';

const Handle = SortableHandle(({ color }: any) => (
  <button className="ml-auto cursor-grab drag-handle p-2">
    <IconDrag color={color} />
  </button>
));

const SortableItem = SortableElement(({ value: item, onClickItem, color, activeItem }: any) => {
  const handleClickItem = useCallback(
    (item: ArtworkItem) => (e: React.MouseEvent) => {
      e.preventDefault();
      onClickItem(item);
    },
    [onClickItem],
  );

  const active = activeItem.title === item.title;

  return (
    <li
      className={`flex items-center border-b py-2 lg:py-1 pl-5 lg:pl-3 post-wrap ${item.index === 0 ? 'hidden' : ''}`}
      style={{ borderColor: ArtworkColorsText[color], ...(active ? { color: ArtworkColorsReverse[color] } : {}) }}>
      <style>{`
      .playlist-link:hover {
        color: ${ArtworkColorsReverse[color]};
      }
    `}</style>
      <a
        href={`#${item.title}`}
        className="playlist-link flex flex-grow"
        data-tip
        data-for={`listTitle${item.index}`}
        onClick={handleClickItem(item)}>
        <span className="num w-10 font-bold">{item.index}.</span>
        <div className="flex flex-col md:flex-row font-bold">
          <span className="mb-1 md:mb-0 mr-8">{item.name}</span>
          <span className="flex justify-start items-center">
            {active && <IconPlay className="w-6 h-6 -m-1 mr-1" />}
            <span>{item.title}</span>
          </span>
        </div>
      </a>
      <Handle color={color} />
    </li>
  );
});

const SortableList = SortableContainer(({ items, onClickItem, color, activeItem }: any) => {
  return (
    <ul className="border-t" style={{ borderColor: ArtworkColorsText[color] }}>
      {items.map((item: ArtworkItem, index: number) => (
        <SortableItem key={item.title} index={index} value={{ ...item, index }} onClickItem={onClickItem} color={color} activeItem={activeItem} />
      ))}
    </ul>
  );
});

interface Props {
  list: ArtworkItem[];
  activeItem: ArtworkItem;
  onUpdateList: (list: ArtworkItem[]) => void;
  onClickItem: (item: ArtworkItem) => void;
  color: string;
}

const ContentPlaylist: React.FC<Props> = ({ list, onClickItem, onUpdateList, color, activeItem }) => {
  const { visibleContentIndex, setVisibleContent } = useUI();

  const handleVisible = (num: number) => () => {
    if (visibleContentIndex === num) setVisibleContent(undefined);
    else setVisibleContent(num);
  };

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      const newList: ArtworkItem[] = JSON.parse(JSON.stringify(moveArrayItemToNewIndex(list, oldIndex, newIndex)));
      onUpdateList(newList);
    },
    [onUpdateList, list],
  );

  return (
    <div className="w-full lg:w-5/12 lg:ml-10 lg:pr-6">
      <h3
        className={`lg:mb-3 text-base xl:text-xl border-t ${visibleContentIndex === 9999 ? '' : 'border-b'} lg:border-none `}
        style={{ borderColor: ArtworkColorsText[color] }}
        onClick={handleVisible(9999)}>
        <span className="flex px-4 lg:px-6 lg:px-0 py-4 lg:py-0">플레이리스트</span>
      </h3>
      <div className={`${visibleContentIndex === 9999 ? 'block' : 'hidden'} lg:block`}>
        <SortableList
          items={list}
          onSortEnd={onSortEnd}
          onClickItem={onClickItem}
          useDragHandle={true}
          helperClass="border-t text-orange font-black z-50"
          color={color}
          activeItem={activeItem}
        />
        {list.map((item, i) =>
          i === 0 ? null : (
            <ReactTooltip
              key={item.title}
              id={`listTitle${i}`}
              aria-haspopup="true"
              backgroundColor="rgba(0,0,0,0)"
              arrowColor="rgba(0,0,0,0)"
              className="desktop-only">
              <img src={item.preview} alt={item.title} className="w-60" />
            </ReactTooltip>
          ),
        )}
      </div>
    </div>
  );
};

export default ContentPlaylist;
