import './lib/prefixfree.min';
import './lib/GeometryUtils';
import './lib/BufferGeometryUtils';
import { noise } from './lib/Noise';
import { preloadAssets, checkMobile, checkIE, remapVal } from './utils';
import { threeOpt, assetIndex } from './config';

const THREE = window.THREE;
const TweenMax = window.TweenMax;
const Linear = window.Linear;
const Power4 = window.Power4;
const Elastic = window.Elastic;
const Power2 = window.Power2;

let isCanvasInit = false;
let containerElement = null;
let ptcIndex = {};
let artworkItem = null;
let artworkLabel = null;
const mouseOpt = { pos: { now: { x: undefined, y: undefined } } };

const CanvasStatus = {
  hide: 'hide',
  push: 'push',
  home: 'home',
};

var commoners = {}; // @cna97-commoners_custom

function useParticle() {
  function initParticle(element) {
    containerElement = element;
    isCanvasInit = true;

    preloadAssets(assetIndex).then(() => {
      // create threeOpt parameters
      threeOpt.renderer = {};
      threeOpt.morph.default = {
        speed: threeOpt.ptc.speed.normal,
        color: threeOpt.ptc.color,
      };
      threeOpt.noise.seed = noise.seed(Math.random());
      threeOpt.noise.timer = 0;
      threeOpt.noise.threshold = threeOpt.noise.threshold * 2 - 1;
      threeOpt.noise.recoverStep = 1 / threeOpt.noise.recoverTime;
      threeOpt.text.switchId = 0;
      threeOpt.text.switchNum = 0;
      threeOpt.text.currentId = null;

      // create mouseOpt parameters
      mouseOpt.pos = { past: {}, now: {} };
      mouseOpt.delta = 1;
      mouseOpt.isMobile = checkMobile();
      mouseOpt.evt = {
        hover: false,
        focus: false,
      };
      mouseOpt.evt.click = 'click';
      if (mouseOpt.isMobile) {
        mouseOpt.evt.enter = 'touchstart';
        mouseOpt.evt.leave = 'touchend';
        mouseOpt.evt.move = 'touchmove';
      } else {
        mouseOpt.evt.enter = 'mouseenter';
        mouseOpt.evt.leave = 'mouseout';
        mouseOpt.evt.move = 'mousemove';
      }
      mouseOpt.isIE = checkIE();
      if (mouseOpt.isIE) {
      } else {
      }

      /* initialize three */
      // create three.scene
      threeOpt.scene.obj = new THREE.Scene();

      // create three.renderer
      threeOpt.renderer.obj = new THREE.WebGLRenderer({
        alpha: true,
        powerPreference: 'high-performance',
      });
      threeOpt.renderer.obj.setPixelRatio(window.devicePixelRatio);
      updateWin();
      threeOpt.renderer.obj.domElement.id = 'intro_cvs';
      containerElement.appendChild(threeOpt.renderer.obj.domElement);

      // update all size factors related to window size (max: 1920px > min: 480px)
      var ptcSizeBounds = [threeOpt.ptc.size, threeOpt.ptc.size * threeOpt.ptc.mobileSizeCoe];
      var ptcNumBounds = [threeOpt.ptc.num, Math.round(threeOpt.ptc.num * threeOpt.ptc.mobileNumCoe)];
      var textSizeBounds = [threeOpt.text.size, threeOpt.text.size * threeOpt.text.mobileSizeCoe];
      var cursorDistBounds = [threeOpt.pop.cursorDist, threeOpt.pop.cursorDist * threeOpt.pop.mobileDistCoe];
      var widthBounds = [1280, 480];
      var tempWidth = Math.max(Math.min(threeOpt.renderer.size.width, widthBounds[0]), widthBounds[1]);
      threeOpt.ptc.size = remapVal(tempWidth, widthBounds[0], widthBounds[1], ptcSizeBounds[0], ptcSizeBounds[1]);
      threeOpt.ptc.num = remapVal(tempWidth, widthBounds[0], widthBounds[1], ptcNumBounds[0], ptcNumBounds[1]);
      threeOpt.text.size = remapVal(tempWidth, widthBounds[0], widthBounds[1], textSizeBounds[0], textSizeBounds[1]);
      threeOpt.pop.cursorDist = remapVal(tempWidth, widthBounds[0], widthBounds[1], cursorDistBounds[0], cursorDistBounds[1]);
      threeOpt.ptc.numPerChar = Math.round(threeOpt.ptc.num / assetIndex.tex.charAll.length);
      threeOpt.ptc.num = threeOpt.ptc.numPerChar * assetIndex.tex.charAll.length;

      // create three.camera
      threeOpt.camera.obj = new THREE.PerspectiveCamera(
        threeOpt.camera.fov,
        threeOpt.camera.aspect,
        threeOpt.camera.visible.near * threeOpt.camera.dist,
        threeOpt.camera.visible.far * threeOpt.camera.dist,
      );
      threeOpt.camera.obj.position.copy(threeOpt.camera.pos);

      // create three.interaction
      new THREE.Interaction(threeOpt.renderer.obj, threeOpt.scene.obj, threeOpt.camera.obj);

      // create three.ptcGeo
      threeOpt.ptc.obj = [];
      threeOpt.ptc.sys = new THREE.Group();

      for (var i = 0; i < assetIndex.tex.charAll.length; i++) {
        var ptcGeo = new THREE.BufferGeometry();
        createPtcVertices(ptcGeo);
        threeOpt.ptc.obj.push(ptcGeo);

        var ptcMat = new THREE.PointsMaterial({
          size: threeOpt.ptc.size * threeOpt.scene.size.width,
          map: assetIndex.tex.charAll[i],
          depthTest: false,
          transparent: true,
        });

        var ptcSys = new THREE.Points(ptcGeo, ptcMat);
        ptcSys.sortParticles = true;
        threeOpt.ptc.sys.add(ptcSys);
      }

      threeOpt.scene.obj.add(threeOpt.ptc.sys);

      // create three.text
      threeOpt.pop.cursorDist = threeOpt.pop.cursorDist * threeOpt.text.size * threeOpt.scene.size.width;
      threeOpt.text.obj = [];
      threeOpt.text.maxVolume = createTextGeo(ptcIndex[artworkLabel.uuid]).textVolume;
      threeOpt.text.switchKeyArr = [];
      Object.keys(ptcIndex).forEach(function (key) {
        var data = ptcIndex[key];
        if (data.switchTime !== null) {
          threeOpt.text.switchNum++;
          threeOpt.text.switchKeyArr.push(key);
        }
      });

      Object.keys(ptcIndex).forEach(function (key, i) {
        var data = ptcIndex[key];
        data.key = key;
        data.textBackUp = JSON.parse(JSON.stringify(data.text));
        var textObj = createTextObj(data, key);
        threeOpt.text.obj.push(textObj);

        // @cna97-commoners_custom
        if (data.name === '커머너즈') {
          commoners.key = key;
          commoners.data = data;
          commoners.textObj = threeOpt.text.obj[i];
          commoners.textObjId = i;
        }
      });

      /* initialize event listeners */
      window.addEventListener('resize', updateWin, false);
      window.addEventListener(mouseOpt.evt.move, getMousePos, false);
      window.addEventListener(mouseOpt.evt.leave, removeMousePos, false);

      // /* start animation */
      removeMousePos();
      animate();
    });
  }

  // @cna97-commoners_custom
  function updateCustomTextObj(options = {}) {
    const { restore = false, label = '' } = options;

    if (threeOpt.text.currentId === commoners.key) {
      if (restore && commoners.data && commoners.data.textBackUp) {
        // 커머너즈 창에서 벗어날 때(앞, 뒤 콘텐츠 이동, 메인 페이지로 이동 등)
        commoners.data.text = JSON.parse(JSON.stringify(commoners.data.textBackUp));
      } else {
        // 사용자가 입력한 텍스트로 바꾸기
        commoners.data.text = [label];
      }
      threeOpt.text.obj.splice(commoners.textObjId, 1, createTextObj(commoners.data, commoners.key));
      morphPtc(threeOpt.ptc.obj, threeOpt.text.obj[commoners.textObjId].ptcGeo, 'home');
    }
  }

  function removeMousePos() {
    mouseOpt.pos.now = { x: Infinity, y: Infinity };
    mouseOpt.delta = 1;
  }

  function getMousePos(evt) {
    var srcX, srcY;
    if (mouseOpt.isMobile) {
      srcX = evt.touches[0].pageX;
      srcY = evt.touches[0].pageY;
    } else {
      srcX = evt.clientX;
      srcY = evt.clientY;
    }

    var x = remapVal(srcX, 0, threeOpt.renderer.size.width, -threeOpt.scene.size.width / 2, threeOpt.scene.size.width / 2);
    var y = remapVal(srcY, 0, threeOpt.renderer.size.height, threeOpt.scene.size.height / 2, -threeOpt.scene.size.height / 2);

    mouseOpt.pos.now = { x: x, y: y };
    threeOpt.text.timer = 0;
  }

  /**
   * Push: 파티클 아래쪽이 위로 짜부되는 것 (구겨짐)
   */
  function pushParticle() {
    const textObj = threeOpt.text.obj.find(({ id }) => artworkLabel.uuid === id);
    if (textObj) morphPtc(threeOpt.ptc.obj, textObj.ptcGeo, CanvasStatus.push);
  }

  /**
   * Hide: 파티클이 화변 밖 사방으로 터져나가서 안보이게 되는 것
   */
  function hideParticle() {
    const textObj = threeOpt.text.obj.find(({ id }) => artworkLabel.uuid === id);
    if (textObj) morphPtc(threeOpt.ptc.obj, textObj.ptcGeo, CanvasStatus.hide);
  }

  /**
   * Default: 파티클이 글자 형태로 있는 것 (기본)
   * @param {ArtworkItem} item
   * @param {ItemCanvasLabel} label
   */
  function drawParticle() {
    updateCustomTextObj({ restore: true });
    const data = ptcIndex[artworkLabel.uuid];
    const textObj = threeOpt.text.obj.find(({ id }) => data.uuid === id);
    if (textObj) morphTo(threeOpt.ptc.obj, textObj, data);
  }

  /**
   * @param {any} data
   */
  function updateState(data = {}) {
    if (data.item) artworkItem = data.item;
    if (data.label) artworkLabel = data.label;
    if (!isCanvasInit && data.list) {
      data.list.forEach((item) => {
        item.canvas.labels.forEach((label) => {
          ptcIndex[label.uuid] = {
            ...item,
            ...label,
            ...item.canvas,
            background: item.thumbnail,
          };
        });
      });
    }
  }

  /**
   * 사용자 커스텀 텍스트로 캔버스 드로잉 처리
   * @param {string} label
   * @returns
   */
  function drawParticleCustom(label) {
    updateCustomTextObj({ restore: false, label });
  }

  return {
    initParticle,
    pushParticle,
    hideParticle,
    drawParticle,
    drawParticleCustom,
    updateState,
    isCanvasInit,
  };
}

export default useParticle;

function updateWin() {
  threeOpt.renderer.size = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  threeOpt.scene.size.height = threeOpt.scene.size.width / (threeOpt.renderer.size.width / threeOpt.renderer.size.height);
  threeOpt.scene.size.halfDiagonal = Math.sqrt(Math.pow(threeOpt.scene.size.height, 2) + Math.pow(threeOpt.scene.size.width, 2)) * 0.65;
  threeOpt.camera.aspect = threeOpt.renderer.size.width / threeOpt.renderer.size.height;
  threeOpt.push.limitPerWidth = threeOpt.scene.size.width * (threeOpt.push.limitPerWidth - 0.5);
  threeOpt.push.yFactor.translate = threeOpt.scene.size.width * threeOpt.push.yFactor.translate;

  var fovVertical = (threeOpt.camera.fov * Math.PI) / 180;
  var tempCamDepth = threeOpt.renderer.size.height / 2 / Math.tan(fovVertical / 2);
  var fovHorizontal = Math.atan(threeOpt.renderer.size.width / 2 / tempCamDepth) * 2;

  threeOpt.camera.dist = 100 / Math.tan(fovHorizontal / 2);
  threeOpt.camera.pos = new THREE.Vector3(threeOpt.camera.vec.x, threeOpt.camera.vec.y, threeOpt.camera.vec.z)
    .normalize()
    .multiplyScalar(threeOpt.camera.dist);

  if (threeOpt.camera.obj) {
    threeOpt.camera.obj.near = threeOpt.camera.dist * threeOpt.camera.visible.near;
    threeOpt.camera.obj.far = threeOpt.camera.dist * threeOpt.camera.visible.far;
    threeOpt.camera.obj.aspect = threeOpt.camera.aspect;
    threeOpt.camera.obj.position.copy(threeOpt.camera.pos);
    threeOpt.camera.obj.updateProjectionMatrix();
  }
  if (threeOpt.renderer.obj) {
    threeOpt.renderer.obj.setSize(threeOpt.renderer.size.width, threeOpt.renderer.size.height);
    threeOpt.renderer.obj.setPixelRatio(window.devicePixelRatio);
  }
}

function createPtcVertices(ptcGeo) {
  var ptcVertices = [];
  for (var j = 0; j < threeOpt.ptc.numPerChar; j++) {
    var theta = Math.random() * Math.PI * 2;
    ptcVertices.push(
      Math.cos(theta) * threeOpt.scene.size.halfDiagonal,
      Math.sin(theta) * threeOpt.scene.size.halfDiagonal,
      Math.random() * threeOpt.camera.dist,
    );
  }

  ptcGeo.visibleNum = assetIndex.tex.charAll.length;
  ptcGeo.setAttribute('position', new THREE.Float32BufferAttribute(ptcVertices, 3));
}

function getVolume(geo) {
  if (!geo.isBufferGeometry) {
    console.warn("'geometry' must be an indexed or non-indexed buffer geometry");
    return 0;
  }
  var isId = geo.index !== null;
  let pos = geo.attributes.position;
  let sum = 0;
  let p1 = new THREE.Vector3(),
    p2 = new THREE.Vector3(),
    p3 = new THREE.Vector3();
  if (!isId) {
    let faces = pos.count / 3;
    for (let i = 0; i < faces; i++) {
      p1.fromBufferAttribute(pos, i * 3 + 0);
      p2.fromBufferAttribute(pos, i * 3 + 1);
      p3.fromBufferAttribute(pos, i * 3 + 2);
      sum += signedVolumeOfTriangle(p1, p2, p3);
    }
  }
  return sum;
}

function signedVolumeOfTriangle(p1, p2, p3) {
  return p1.dot(p2.cross(p3)) / 6.0;
}

function animate() {
  window.requestAnimationFrame(animate);
  threeOpt.noise.timer += threeOpt.noise.speed;

  updateMousePos();
  switchText();

  for (var i = 0; i < threeOpt.ptc.obj.length; i++) {
    var ptcGeo = threeOpt.ptc.obj[i];
    ptcGeo.attributes.position.needsUpdate = true;
    ptcGeo.verticesNeedUpdate = true;
    threeOpt.ptc.sys.children[i].material.color = new THREE.Color(threeOpt.morph.default.color);

    if (!threeOpt.morph.activate) {
      moveText(ptcGeo);
    }
  }

  render();
}

function updateMousePos() {
  if (mouseOpt.pos.now.x !== Infinity || mouseOpt.pos.now.y !== Infinity) {
    if (mouseOpt.pos.past.x - mouseOpt.pos.now.x + (mouseOpt.pos.past.y - mouseOpt.pos.now.y) !== 0) {
      mouseOpt.delta = 0;
    } else {
      if (mouseOpt.delta <= 1 - threeOpt.noise.recoverStep) {
        mouseOpt.delta += threeOpt.noise.recoverStep;
      }
    }
  } else {
    mouseOpt.delta = 1;
  }
  mouseOpt.pos.past = JSON.parse(JSON.stringify(mouseOpt.pos.now));
}

function render() {
  threeOpt.renderer.obj.render(threeOpt.scene.obj, threeOpt.camera.obj);
}
function switchText() {
  if (threeOpt.text.timer === threeOpt.text.switchTime) {
    // threeOpt.text.switchId++;
    if (threeOpt.text.switchId === threeOpt.text.switchNum) threeOpt.text.switchId = 0;
    morphTo(threeOpt.ptc.obj, threeOpt.text.obj[threeOpt.text.switchId], ptcIndex[threeOpt.text.switchKeyArr[threeOpt.text.switchId]]);
  }
}

function moveText(ptcGeo) {
  for (var i = 0; i < ptcGeo.visibleNum; i++) {
    var stride = i * 3;
    var x = ptcGeo.attributes.position.array[stride + 0];
    var y = ptcGeo.attributes.position.array[stride + 1];

    var homeX = ptcGeo.attributes.home.array[stride + 0];
    var homeY = ptcGeo.attributes.home.array[stride + 1];

    var velX = ptcGeo.attributes.velocity.array[stride + 0];
    var velY = ptcGeo.attributes.velocity.array[stride + 1];

    var homeDX = homeX - x;
    var homeDY = homeY - y;
    var homeDist = Math.sqrt(Math.pow(homeDX, 2) + Math.pow(homeDY, 2));
    var homeForce = homeDist * threeOpt.pop.homeForceScale;
    var homeAngle = Math.atan2(homeDY, homeDX);

    var cursorForce = 0;
    var cursorAngle = 0;

    var cursorDX = x - mouseOpt.pos.now.x;
    var cursorDY = y - mouseOpt.pos.now.y;
    var cursorDistSquared = Math.pow(cursorDX, 2) + Math.pow(cursorDY, 2);
    cursorForce = Math.min(threeOpt.pop.cursorDist / cursorDistSquared, threeOpt.pop.cursorDist);
    cursorAngle = Math.atan2(cursorDY, cursorDX);

    velX += homeForce * Math.cos(homeAngle) + cursorForce * Math.cos(cursorAngle);
    velY += homeForce * Math.sin(homeAngle) + cursorForce * Math.sin(cursorAngle);

    velX *= threeOpt.pop.decelForce;
    velY *= threeOpt.pop.decelForce;

    var fleePower = 1;
    var fleeNoise = noise.simplex3(
      remapVal(homeX, -threeOpt.scene.size.width / 2, threeOpt.scene.size.width / 2, 0, 1),
      remapVal(homeY, -threeOpt.scene.size.height / 2, threeOpt.scene.size.height / 2, 0, 1),
      threeOpt.noise.timer,
    );
    if (fleeNoise > threeOpt.noise.threshold) {
      fleePower = threeOpt.noise.power.flee * (fleeNoise - threeOpt.noise.threshold) * mouseOpt.delta + 1;
    }

    ptcGeo.attributes.position.array[stride + 0] +=
      velX +
      noise.simplex3(homeX % threeOpt.scene.size.width, homeY % threeOpt.scene.size.height, threeOpt.noise.timer) *
        threeOpt.noise.power.normal *
        fleePower;
    ptcGeo.attributes.position.array[stride + 1] +=
      velY +
      noise.simplex3(homeY % threeOpt.scene.size.height, homeX % threeOpt.scene.size.width, threeOpt.noise.timer) *
        threeOpt.noise.power.normal *
        fleePower;

    ptcGeo.attributes.velocity.array[stride + 0] = velX;
    ptcGeo.attributes.velocity.array[stride + 1] = velY;
  }
}
function morphTo(srcPtc, targetObj, data) {
  threeOpt.text.timer = 0;
  threeOpt.text.currentId = targetObj.id;

  if (data) {
    if (data.switchTime === null) {
      threeOpt.text.switchable = false;
    } else {
      threeOpt.text.switchable = true;
      threeOpt.text.switchTime = parseInt(data.switchTime);

      threeOpt.text.switchId = targetObj.id;
    }

    TweenMax.to(threeOpt.morph.default, threeOpt.morph.time.color, {
      ease: Linear.easeNone,
      color: data.color,
    });
  }

  TweenMax.to(threeOpt.morph.default, threeOpt.morph.time.speed, {
    ease: Power4.easeIn,
    speed: threeOpt.ptc.speed.morph,
    onComplete: slowDown,
  });

  morphPtc(srcPtc, targetObj.ptcGeo, CanvasStatus.home);
}

function morphPtc(srcPtc, targetPtc, attrKey) {
  threeOpt.morph.activate = true;
  for (var i = 0; i < srcPtc.length; i++) {
    var ptcGeo = srcPtc[i];
    if (ptcGeo.attributes.home) {
      ptcGeo.deleteAttribute('home');
    }
    if (ptcGeo.attributes.vel) {
      ptcGeo.deleteAttribute('velocity');
    }
    ptcGeo.setAttribute('home', targetPtc[i].attributes[attrKey]);
    ptcGeo.setAttribute('velocity', getVelocity(ptcGeo.attributes.position));
    ptcGeo.visibleNum = targetPtc[i].visibleNum;

    TweenMax.to(ptcGeo.attributes.position.array, threeOpt.morph.time.move, targetPtc[i].attributes[attrKey].array);
  }

  TweenMax.to(threeOpt.morph.default, threeOpt.morph.time.move, {
    ease: Elastic.easeOut.config(0.1, 0.3),
    onComplete: endMorphing,
  });
}

function endMorphing() {
  threeOpt.morph.activate = false;
}

function getVelocity(src) {
  var num = src.count;
  var ptcVertices = [];

  for (var i = 0; i < num; i++) {
    ptcVertices.push(0, 0, 0);
  }

  return new THREE.Float32BufferAttribute(ptcVertices, 3);
}

function slowDown() {
  TweenMax.to(threeOpt.morph.default, 0.3, {
    ease: Power2.easeOut,
    speed: threeOpt.ptc.speed.normal,
    delay: 0.2,
  });
}

function createTextObj(data, id) {
  var textObj = {};
  textObj.id = id;
  var textGeoData = createTextGeo(data);
  textObj.textGeo = textGeoData.textGeo;
  textObj.textGeo.center();
  textObj.textGeo.translate(0, threeOpt.scene.size.height * threeOpt.text.translate.y, 0);

  var ptcDensity = Math.min(Math.max(textGeoData.textVolume / threeOpt.text.maxVolume, 0), 1);

  textObj.ptcGeo = [];
  for (var j = 0; j < assetIndex.tex.charAll.length; j++) {
    var ptcGeo = new THREE.BufferGeometry();
    var ptcVertices = THREE.GeometryUtils.randomPointsInBufferGeometry(textObj.textGeo, threeOpt.ptc.numPerChar);
    createTextVertices(ptcGeo, ptcVertices, ptcDensity);
    textObj.ptcGeo.push(ptcGeo);
  }
  return textObj;
}

function createTextGeo(data) {
  var textContentArr = data.text;
  var textGeoArr = [];
  var mergedTextGeo;

  for (var i = 0; i < textContentArr.length; i++) {
    var textGeo = new THREE.TextBufferGeometry(textContentArr[i], {
      font: assetIndex.typeface.helveticaNow.obj,
      size: threeOpt.text.size * threeOpt.scene.size.width * data.scale,
      height: threeOpt.text.height,
      curveSegments: threeOpt.text.curveSegments,
    });

    if (textContentArr.length > 1) {
      textGeo.translate(0, -(threeOpt.text.size * threeOpt.text.lineHeight * data.scale * threeOpt.scene.size.width) * i, 0);
      textGeoArr.push(textGeo);
    } else {
      mergedTextGeo = textGeo;
    }
  }

  if (textGeoArr.length > 1) {
    mergedTextGeo = THREE.BufferGeometryUtils.mergeBufferGeometries(textGeoArr);
  }

  return {
    textGeo: mergedTextGeo,
    textVolume: getVolume(mergedTextGeo),
  };
}

function createTextVertices(ptcGeo, pts, density) {
  var ptcVertices = [],
    hideVertices = [],
    pushVertices = [];
  ptcGeo.visibleNum = Math.round(threeOpt.ptc.numPerChar * density);
  for (var i = 0; i < threeOpt.ptc.numPerChar; i++) {
    var vec = new THREE.Vector3(pts[i]['x'], pts[i]['y'], pts[i]['z']).normalize().multiplyScalar(threeOpt.scene.size.halfDiagonal);
    vec.z = Math.random() * threeOpt.camera.dist;
    if (i < ptcGeo.visibleNum) {
      ptcVertices.push(pts[i]['x'], pts[i]['y'], pts[i]['z']);
      var pushX = pts[i]['x'];
      var pushY = threeOpt.push.yFactor.translate * ((Math.random() - 0.5) * 2 * threeOpt.push.yFactor.randomDom + 1);
      if (pts[i]['y'] < threeOpt.push.limitPerWidth) {
        var dist = threeOpt.push.limitPerWidth - pts[i]['y'];
        pushX = pts[i]['x'] + (Math.random() - 0.5) * 2 * Math.pow(dist * threeOpt.push.xFactor.distCoe, threeOpt.push.xFactor.pow);
        pushY +=
          threeOpt.push.limitPerWidth -
          Math.pow(threeOpt.push.limitPerWidth - pts[i]['y'], threeOpt.push.yFactor.pow) *
            ((Math.random() - 0.5) * 2 * threeOpt.push.yFactor.randomDom + 1);
      } else {
        pushY += pts[i]['y'];
      }
      pushVertices.push(pushX, pushY, pts[i]['z']);
    } else {
      ptcVertices.push(vec.x, vec.y, vec.z);
      pushVertices.push(vec.x, vec.y, vec.z);
    }
    hideVertices.push(vec.x, vec.y, vec.z);
  }

  ptcGeo.setAttribute('position', new THREE.Float32BufferAttribute(ptcVertices, 3));
  ptcGeo.setAttribute(CanvasStatus.home, new THREE.Float32BufferAttribute(ptcVertices, 3));
  ptcGeo.setAttribute(CanvasStatus.hide, new THREE.Float32BufferAttribute(hideVertices, 3));
  ptcGeo.setAttribute(CanvasStatus.push, new THREE.Float32BufferAttribute(pushVertices, 3));
}
