export function checkMobile() {
  // eslint-disable-next-line no-undef
  return 'ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch);
}

export function checkIE() {
  return window.navigator.userAgent.match(/MSIE|Trident/) !== null;
}

export function remapVal(val, srcMin, srcMax, dstMin, dstMax) {
  return dstMin + ((dstMax - dstMin) * (val - srcMin)) / (srcMax - srcMin);
}

export function preloadAssets(assetIndex) {
  return new Promise(resolve => {
    var remainTypefaceNum = 0, remainTexNum = 0, remainSoundNum = 0;
    var typefaceNameArr = [], texNameArr = [], soundNameArr = [];

    Object.keys(assetIndex.typeface).forEach(function (key) {
      typefaceNameArr.push(key);
      remainTypefaceNum++;
    });

    Object.keys(assetIndex.tex).forEach(function (key) {
      texNameArr.push(key);
      remainTexNum++;
    });

    Object.keys(assetIndex.sound).forEach(function (key) {
      soundNameArr.push(key);
      remainSoundNum++;
    });

    // load fontfaces
    var fontLoader = new THREE.FontLoader();
    typefaceNameArr.forEach(function (typefaceKey) {
      var typefaceDir = assetIndex.typeface[typefaceKey].dir;
      fontLoader.load(typefaceDir, function (font) {
        remainTypefaceNum--;
        assetIndex.typeface[typefaceKey].obj = font;

        // load textures
        if (remainTypefaceNum <= 0) {
          var texureLoader = new THREE.TextureLoader();
          assetIndex.tex.charAll = [];
          texNameArr.forEach(function (texKey) {
            var texDir = assetIndex.tex[texKey].dir;
            texureLoader.load(texDir, function (tex) {
              remainTexNum--;
              // tex.flipY = false;
              tex.needsUpdate = true;
              assetIndex.tex[texKey].obj = tex;
              if (texKey.split("_")[0] === "char") {
                assetIndex.tex.charAll.push(tex);
              }

              // load sounds
              if (remainTexNum === 0) {
                resolve();
              }
            })
          })
        }
      });
    })
  }
  )
}
