/**
 * XSS 방지
 * @param origin
 * @returns
 */
export function filteringXSS(origin: string) {
  /* eslint-disable-next-line */
  return origin.replace(/\<|\>|\"|\'|\%|\;|\(|\)|\&|\+|\-/g, '');
}

export function getDaysArray(start: Date, end: Date) {
  for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr;
}

export const debounce = <T extends (...args: any[]) => any>(callback: T, waitFor = 300) => {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: Parameters<T>): ReturnType<T> => {
    let result: any;
    timeout && clearTimeout(timeout);
    timeout = setTimeout(() => {
      result = callback(...args);
    }, waitFor);
    return result;
  };
};

/**
 * addComma
 * 가격 정보에 comma 처리
 */
export function addComma(number: number | string): string {
  const price = number + '';
  if (!price || price.indexOf(',') !== -1) return price;
  return price.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
}

export function checkExceedFileSize(file: File, limit = 3): boolean {
  const filesize = (file.size / 1024 / 1024).toFixed(4); // MB
  if (Number(filesize) > limit) {
    return true;
  }
  return false;
}

export function moveArrayItemToNewIndex(arr: Array<any>, oldIndex: number, newIndex: number) {
  if (newIndex >= arr.length) {
    var k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
}

export function throttle(fn: Function, wait = 300) {
  let isCalled = false;

  return function (...args: any) {
    if (!isCalled) {
      fn(...args);
      isCalled = true;
      setTimeout(function () {
        isCalled = false;
      }, wait);
    }
  };
}
