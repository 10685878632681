import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Main from '@/page/Main';
import Spinner from '@/components/common/Spinner';
import { ProvideUI } from '@/store/ui';
import { Paths } from '@/utils/constants';

const App: React.FC = () => (
  <ProvideUI>
    <Spinner />
    <Router>
      <Switch>
        <Route path={Paths.main}>
          <Main />
        </Route>
        <Route path="*">
          <Redirect to={Paths.main} />
        </Route>
      </Switch>
    </Router>
  </ProvideUI>
);

export default App;
