import React, { useCallback, useMemo, useEffect } from 'react';
import Portal from './Portal';

interface DimProps {
  active: boolean;
  onClick?: (e: React.MouseEvent) => void;
  wrapperClassName?: string;
  className?: string;
}

const Dim: React.FC<DimProps> = ({ active, onClick, className = '', wrapperClassName = '' }: DimProps) => {
  useEffect(() => {
    document.body.style.overflow = active ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [active]);

  const dimStyle = useMemo(() => ({ display: active ? 'block' : 'none' }), [active]);

  const handleAnimationEnd = useCallback(
    (e) => {
      if (!active && typeof onClick === 'function') onClick(e);
    },
    [active, onClick],
  );

  const handleDimClick = useCallback(
    (e) => {
      if (typeof onClick === 'function') onClick(e);
    },
    [onClick],
  );

  return (
    <Portal>
      <div className={wrapperClassName}>
        <div
          className={`dim fixed top-0 left-0 w-full h-full z-10 animated ${active ? 'fadeIn' : 'fadeOut'} ${className}`}
          onAnimationEnd={handleAnimationEnd}
          style={{ ...dimStyle, backgroundColor: 'rgba(0, 0, 0, 0.5' }}
          onClick={handleDimClick}
        />
      </div>
    </Portal>
  );
};

export default Dim;
