import React, { useContext, createContext, useState, useCallback } from 'react';
import Player from '@vimeo/player';

const uiContext = createContext<any>(undefined);

type UIStore = {
  // isShowModal: boolean;
  // showModal: () => void;
  // hideModal: () => void;
  // isShowSide: boolean;
  // showSide: () => void;
  // hideSide: () => void;
  // toggleSide: () => void;
  isShowSpinner: boolean;
  showSpinner: () => void;
  hideSpinner: () => void;

  isActiveAction: boolean;
  showActiveAction: () => void;
  hideActiveAction: () => void;

  isActiveCanvas: boolean;
  showCanvas: () => void;
  hideCanvas: () => void;

  activeCanvasLabelIndex: number;
  setActiveLabelIndex: (index: number) => void;

  visibleContentIndex: number;
  setVisibleContent: (index?: number) => void;

  activeGalleryIndex: number;
  setGalleryIndex: (index?: number) => void;

  player: Player;
  setPlayer: (p?: Player) => void;
};

export function useUI(): UIStore {
  return useContext(uiContext);
}

function useProvideUI() {
  // const [isShowModal, setModal] = useState<boolean>(false);
  // const [isShowSide, setSideVisible] = useState<boolean>(false);
  const [isShowSpinner, setSpinner] = useState<boolean>(false);

  // const showModal = useCallback(() => setModal(true), []);
  // const hideModal = useCallback(() => setModal(false), []);
  // const showSide = useCallback(() => setSideVisible(true), []);
  // const hideSide = useCallback(() => setSideVisible(false), []);
  // const toggleSide = useCallback(() => setSideVisible(!isShowSide), [isShowSide]);
  const showSpinner = useCallback(() => setSpinner(true), []);
  const hideSpinner = useCallback(() => setSpinner(false), []);

  const [isActiveAction, setActiveAction] = useState(true);

  const showActiveAction = useCallback(() => setActiveAction(true), []);
  const hideActiveAction = useCallback(() => setActiveAction(false), []);

  const [isActiveCanvas, setActiveCanvas] = useState(true);
  const showCanvas = useCallback(() => setActiveCanvas(true), []);
  const hideCanvas = useCallback(() => setActiveCanvas(false), []);

  const [activeCanvasLabelIndex, setActiveLabelIndex] = useState<number>(0);
  const [player, setPlayer] = useState<Player>();

  const [visibleContentIndex, setVisibleContent] = useState<number>();

  const [activeGalleryIndex, setGalleryIndex] = useState(-1);

  return {
    // isShowModal,
    // showModal,
    // hideModal,

    // isShowSide,
    // showSide,
    // hideSide,
    // toggleSide,

    isShowSpinner,
    showSpinner,
    hideSpinner,

    isActiveAction,
    showActiveAction,
    hideActiveAction,

    isActiveCanvas,
    showCanvas,
    hideCanvas,

    activeCanvasLabelIndex,
    setActiveLabelIndex,

    player,
    setPlayer,

    visibleContentIndex,
    setVisibleContent,

    activeGalleryIndex,
    setGalleryIndex,
  };
}

export const ProvideUI: React.FC = ({ children }) => {
  const ui = useProvideUI();
  return <uiContext.Provider value={ui}>{children}</uiContext.Provider>;
};
