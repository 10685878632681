import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import IconPause from './icon/IconPause';
import IconPlay from './icon/IconPlay';

interface ContentAudioProps {
  activeColor?: string;
  src: string;
  buttonClassName?: string;
}

const ContentAudio: React.FC<ContentAudioProps> = ({ activeColor, buttonClassName, src }) => {
  const [currentTime, setCurrentTime] = useState<number>();
  const [durationTime, setDurationTime] = useState<number>(0);
  const [isPlay, setPlay] = useState<boolean>(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const currentTimeToStr = useMemo(() => (currentTime ? calculateTime(currentTime) : '0:00'), [currentTime]);
  const durationTimeToStr = useMemo(() => (durationTime ? calculateTime(durationTime) : '0:00'), [durationTime]);

  useEffect(() => {
    if (audioRef.current) {
      if (isPlay) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlay]);

  useEffect(() => {
    if (inputRef?.current && activeColor) {
      inputRef.current.style.setProperty('--range-active-color', activeColor);
    }
  }, [activeColor]);

  useEffect(() => {
    if (typeof currentTime === 'number' && durationTime && inputRef?.current) {
      const percent = Math.ceil((currentTime / durationTime) * 100) || 0;
      inputRef.current.style.setProperty('--seek-before-width', `${percent >= 100 ? 100 : percent}%`);
    }
  }, [currentTime, durationTime, inputRef]);

  const onChangeRange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (audioRef.current) {
        audioRef.current.currentTime = Number(e.target.value);
        if (isPlay === false) setPlay(true);
      }
    },
    [isPlay, audioRef],
  );

  const onPlaying = useCallback(() => {
    if (audioRef?.current?.currentTime) {
      setCurrentTime(audioRef.current.currentTime);
    }
  }, [audioRef]);

  const onEnded = useCallback(() => {
    setCurrentTime(0);
    setPlay(false);
  }, []);

  const onLoadMeta = () => {
    if (audioRef?.current) {
      const { duration } = audioRef.current;
      if (isNaN(duration)) return;
      setDurationTime(duration);
    }
  };

  return (
    <>
      <div className="audio-container flex items-center px-4 lg:pr-6 lg:pl-4">
        <audio
          ref={audioRef}
          src={src}
          preload="metadata"
          onTimeUpdate={onPlaying}
          onEnded={onEnded}
          onLoadedMetadata={onLoadMeta}
          autoPlay={false}
          className="hidden"
        />
        <button onClick={() => setPlay(!isPlay)}>
          {isPlay ? (
            <IconPause innerClassName={`fill-current ${buttonClassName || 'text-white'}`} />
          ) : (
            <IconPlay innerClassName={`fill-current ${buttonClassName || 'text-white'}`} />
          )}
        </button>
        <div className="ml-3 mr-4 w-32 lg:w-24">
          <span>{currentTimeToStr}</span>
          <span className="mx-1">/</span>
          <span>{durationTimeToStr}</span>
        </div>
        <input ref={inputRef} type="range" className="input-range" max={Math.floor(durationTime)} value={currentTime || 0} onChange={onChangeRange} />
      </div>
    </>
  );
};

export default ContentAudio;

const calculateTime = (secs: number): string => {
  const minutes = Math.floor(secs / 60);
  const seconds = Math.floor(secs % 60);
  const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  return `${minutes}:${returnedSeconds}`;
};
