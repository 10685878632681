import { CSSProperties } from 'react';
import ContentIntroFooter from './ContentIntroFooter';
import ContentAudio from './ContentAudio';
import { useUI } from '@/store/ui';
import { ArtworkData } from '@/data';

const ItemWrapClassName = 'flex flex-col mb-4';
const ItemPClassName = 'mt-1 keep-all';

const ContentIntroExhibition: React.FC<{ color: string; isShowDetail: boolean }> = ({ color, isShowDetail }) => {
  const { visibleContentIndex, setVisibleContent } = useUI();

  const handleVisible = (num: number) => () => {
    if (visibleContentIndex === num) setVisibleContent(undefined);
    else setVisibleContent(num);
  };

  // const borderStyle: CSSProperties = { borderColor: color };
  const borderStyle: CSSProperties = {};

  return (
    <div className="flex flex-col w-full lg:w-7/12">
      <div className="w-full">
        <h3 className="lg:mb-3 text-base xl:text-xl border-t lg:border-none" onClick={handleVisible(0)} style={borderStyle}>
          <span className="flex px-4 lg:px-6 py-4 lg:py-0">전시 서문</span>
        </h3>
        <div
          className={`${visibleContentIndex === 0 ? 'block' : 'hidden'} lg:block px-6 lg:pr-0 py-4 lg:py-0 border-t font-bold lg:border-none`}
          style={borderStyle}>
          <p className="mb-2">
            《빈지 워칭; 14284″》는 경기도미술관이 2018년부터 2020년까지 구입한 작품들을 일반에 처음으로 공개하는 신소장품전이다. 최근 3년간
            경기도미술관은 회화, 드로잉, 판화, 사진, 조각, 공예, 설치, 뉴미디어, 퍼포먼스 등 현대 미술의 모든 장르를 아우르는 작품을 구입했다. 이번
            전시는 신소장품 중 경기도미술관에서 한번도 소개된 적이 없는 작품을 중심으로 2010년 이후의 동시대 미술의 흐름을 보여주는 19점과 전시의
            개념을 담은 디지털 아트워크로 구성하였다.
          </p>
          <p className="mb-2">
            ‘동시대 미술’은 다원적이고 일정한 틀이 없는 것이 특징으로 끊임없이 변화하고 있다. 회화, 드로잉, 조각, 판화 등 전통적인 매체가 번성함은
            물론, 20세기에 등장한 사진과 비디오, 21세기의 첨단 디지털 기술을 기반으로 하는 작품까지 다양한 실험을 통해 예술의 확장을 시도한다. 이번
            전시에 소개되는 작품들 역시 회화에서부터 가상현실을 기반으로 한 뉴미디어에 이르기까지 형식적인 면에서 폭넓은 스펙트럼을 보여줄 뿐만 아니라
            내용적인 면에서도 저마다의 감성으로 동시대를 다채롭게 감각한다.
          </p>
          <p className="mb-2">
            《빈지 워칭; 14284″》는 전시제목을 통해 전시의 개념을 직접적으로 드러낸다. ‘빈지 워칭(binge-watching)’은 우리말로 ‘몰아보기’를 뜻하는
            것으로, ”동일한 프로그램의 에피소드를 두 편 이상 쉬지 않고 지속적으로 보는 것“으로 정의할 수 있다. 전 세계적으로 80%에 육박하는 사람들이
            몰아보기 경험이 있고, 평균적으로 한 번에 3.7시간가량을 몰아보기에 할애하고 있다는 조사결과도 있을 만큼 몰아보기는 이미 동시대의
            사회·문화적 현상으로 자리잡았다고 해도 과언이 아니다. 숫자 ‘14284″’는 14,284초, 즉 약 4시간의 ‘시간’을 의미한다. 이번 전시의 작품들을
            처음부터 끝까지 온전히 감상하는(몰아보는) 데 소요되는 시간이다.{' '}
          </p>
          <p className="mb-2">
            관람객들에게 현대 미술은 수수께끼처럼 느껴진다. 모처럼의 여가 시간을 즐기러 찾은 미술관이지만, 작품을 감상하다보면 오히려 피곤함이
            몰려온다. 일반인들이 한 작품을 감상하는 시간이 짧게는 0.2초에서 평균적으로 15초라고 한다. 이번 전시에서는 14,284초는 아니더라도 한 작품에
            최소 40초 이상을 머물러보기를 제안한다. 그 시간동안 작품과의 대화를 시도해보고, 새로운 질문을 떠올려 보다보면 작품에 한발 다가가 있는
            자신을 발견하게 될 것이다. 일견 난해해 보이는 현대미술도 결국은 우리가 함께 살아가고 있는 현실을, 이 시대를 기반으로 하고 있다. 이번
            전시를 ‘빈지 워칭(몰아보기)’ 하는 것을 통해 우리의 삶과 세계를 새롭게 바라보는 다양한 시선을 경험해보기를 기대한다.{' '}
          </p>
        </div>
      </div>
      <div className="w-full mt-0 lg:mt-6">
        <h3 className="lg:mb-3 text-base xl:text-xl border-t lg:border-none" onClick={handleVisible(1)}>
          <span className="flex px-4 lg:px-6 py-4 lg:py-0">오디오 가이드</span>
        </h3>
        {isShowDetail && (
          <div
            className={`${visibleContentIndex === 1 ? 'block' : 'hidden'} lg:block border-t border-white lg:border-none py-4 lg:py-0 pr-2 lg:pr-0`}>
            <ContentAudio src={ArtworkData[0].audioUrl} activeColor={color} />
          </div>
        )}
      </div>
      <div className="w-full mt-0 lg:mt-6">
        <h3 className="lg:mb-3 text-base xl:text-xl border-t lg:border-none" onClick={handleVisible(2)} style={borderStyle}>
          <span className="flex px-4 lg:px-6 py-4 lg:py-0">전시 크레딧</span>
        </h3>
        <div
          className={`flex flex-col md:flex-row ${
            visibleContentIndex === 2 ? 'flex' : 'hidden'
          } lg:flex px-6 lg:pr-0 py-5 lg:py-0 border-t border-white font-bold lg:border-none`}>
          <div className="flex flex-col w-full md:w-1/2 md:mr-4">
            <div className={ItemWrapClassName}>
              <h3>주최</h3>
              <p className={ItemPClassName}>경기문화재단 (대표이사: 강헌)</p>
            </div>
            <div className={ItemWrapClassName}>
              <h3>주관</h3>
              <p className={ItemPClassName}>경기도미술관 (관장: 안미희)</p>
            </div>
            <div className={ItemWrapClassName}>
              <h3>협찬</h3>
              <p className={ItemPClassName}>삼화페인트</p>
            </div>
            <div className={ItemWrapClassName}>
              <h3>큐레이터</h3>
              <p className={ItemPClassName}>김지희</p>
            </div>
            <div className={ItemWrapClassName}>
              <h3>협력큐레이터</h3>
              <p className={ItemPClassName}>김선영</p>
            </div>
            <div className={ItemWrapClassName}>
              <h3>전시보조</h3>
              <p className={ItemPClassName}>이화림</p>
            </div>
            <div className={ItemWrapClassName}>
              <h3>학예지원</h3>
              <p className={ItemPClassName}>구정화(학예운영실장), 김종길, 이채영, 김현정, 강민지, 방초아, 조은솔</p>
            </div>
            <div className={ItemWrapClassName}>
              <h3>행정지원</h3>
              <p className={ItemPClassName}>안세웅(뮤지엄행정팀장), 서은경, 배지현, 이호선, 이정훈</p>
            </div>
            <div className={ItemWrapClassName}>
              <h3>시설지원</h3>
              <p className={ItemPClassName}>김경욱, 김정윤, 문종욱, 이상숙, 임웅선, 정영애, 조만흥, 천정연, 한종국</p>
            </div>
          </div>
          <div className="flex flex-col w-full md:w-1/2">
            <div className={ItemWrapClassName}>
              <h3>안내</h3>
              <p className={ItemPClassName}>강영아, 강한솔, 박혜진</p>
            </div>
            <div className={ItemWrapClassName}>
              <h3>공간디자인</h3>
              <p className={ItemPClassName}>소소명명(昭昭明明)</p>
            </div>
            <div className={ItemWrapClassName}>
              <h3>그래픽디자인·웹개발</h3>
              <p className={ItemPClassName}>커머너즈</p>
            </div>
            <div className={ItemWrapClassName}>
              <h3>영상제작</h3>
              <p className={ItemPClassName}>정영돈</p>
            </div>
            <div className={ItemWrapClassName}>
              <h3>공간시공</h3>
              <p className={ItemPClassName}>부광건축</p>
            </div>
            <div className={ItemWrapClassName}>
              <h3>작품운송설치</h3>
              <p className={ItemPClassName}>아트스카이</p>
            </div>
            <div className={ItemWrapClassName}>
              <h3>미디어설치</h3>
              <p className={ItemPClassName}>진아트</p>
            </div>
            <div className={ItemWrapClassName}>
              <h3>번역</h3>
              <p className={ItemPClassName}>윤자형</p>
            </div>
            <div className={ItemWrapClassName}>
              <h3>오디오 가이드</h3>
              <p className={ItemPClassName}>이정화</p>
            </div>
            <div className={ItemWrapClassName}>
              <h3>감사드리는 분들</h3>
              <p className={ItemPClassName}>이수영, 최혜경</p>
            </div>
          </div>
        </div>
        <ContentIntroFooter className="hidden lg:block" />
      </div>
    </div>
  );
};

export default ContentIntroExhibition;
