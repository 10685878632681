import { ArtworkItem } from '@/data';
import { useUI } from '@/store/ui';
import ContentAudio from './ContentAudio';

interface Props {
  activeItem: ArtworkItem;
  color: string;
  isShowDetail: boolean;
}

const ContentIntroWork: React.FC<Props> = ({ activeItem, color, isShowDetail }) => {
  const { visibleContentIndex, setVisibleContent } = useUI();

  const handleVisible = (num: number) => () => {
    if (visibleContentIndex === num) setVisibleContent(undefined);
    else setVisibleContent(num);
  };

  const borderStyle: any = { borderColor: color };

  return (
    <div className="flex flex-col w-full lg:w-7/12">
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/2">
          <h3 className="lg:mb-3 border-t lg:border-none text-base xl:text-xl " onClick={handleVisible(0)}>
            <span className="flex px-4 lg:px-6 py-4 lg:py-0"> 작가 소개</span>
          </h3>
          <p
            className={`${visibleContentIndex === 0 ? 'block' : 'hidden'} lg:block px-6 lg:pr-0 py-4 lg:py-0 border-t font-bold lg:border-none`}
            style={borderStyle}>
            {activeItem.aboutArtist}
          </p>
        </div>
        <div className="w-full lg:w-1/2 mt-0">
          <h3 className="lg:mb-3 border-t lg:border-none text-base xl:text-xl " onClick={handleVisible(1)}>
            <span className="flex px-4 lg:px-6 py-4 lg:py-0">작품 소개</span>
          </h3>
          <p className={`${visibleContentIndex === 1 ? 'block' : 'hidden'} lg:block px-6 lg:pr-0 py-4 lg:py-0 font-bold border-t lg:border-none`}>
            {activeItem.aboutWork}
          </p>
        </div>
      </div>
      <div>
        <h3 className="lg:mb-3 border-t lg:border-none text-base xl:text-xl " onClick={handleVisible(2)}>
          <span className="flex px-4 lg:px-6 py-4 lg:py-0"> 오디오 가이드</span>
        </h3>
        <div
          className={`w-full mt-0 lg:mt-6 ${
            visibleContentIndex === 2 ? 'block' : 'hidden'
          } lg:block border-t lg:border-none py-4 pr-2 lg:pr-0 lg:py-0`}>
          {isShowDetail && <ContentAudio src={activeItem.audioUrl} buttonClassName="text-gray-dark" />}
        </div>
      </div>
    </div>
  );
};

export default ContentIntroWork;
