export const assetIndex = {
  typeface: {
    helveticaNow: {dir: '/canvas/Helvetica.json'},
  },
  tex: {
    char_A: {dir: '/canvas/char_A.png'},
    char_B: {dir: '/canvas/char_B.png'},
    char_C: {dir: '/canvas/char_C.png'},
    char_D: {dir: '/canvas/char_D.png'},
    char_E: {dir: '/canvas/char_E.png'},
    char_F: {dir: '/canvas/char_F.png'},
    char_G: {dir: '/canvas/char_G.png'},
    char_H: {dir: '/canvas/char_H.png'},
    char_I: {dir: '/canvas/char_I.png'},
    char_J: {dir: '/canvas/char_J.png'},
    char_K: {dir: '/canvas/char_K.png'},
    char_L: {dir: '/canvas/char_L.png'},
    char_M: {dir: '/canvas/char_M.png'},
    char_N: {dir: '/canvas/char_N.png'},
    char_O: {dir: '/canvas/char_O.png'},
    char_P: {dir: '/canvas/char_P.png'},
    char_Q: {dir: '/canvas/char_Q.png'},
    char_R: {dir: '/canvas/char_R.png'},
    char_S: {dir: '/canvas/char_S.png'},
    char_T: {dir: '/canvas/char_T.png'},
    char_U: {dir: '/canvas/char_U.png'},
    char_V: {dir: '/canvas/char_V.png'},
    char_W: {dir: '/canvas/char_W.png'},
    char_X: {dir: '/canvas/char_X.png'},
    char_Y: {dir: '/canvas/char_Y.png'},
    char_Z: {dir: '/canvas/char_Z.png'},
    char_0: {dir: '/canvas/char_0.png'},
    char_1: {dir: '/canvas/char_1.png'},
    char_2: {dir: '/canvas/char_2.png'},
    char_3: {dir: '/canvas/char_3.png'},
    char_4: {dir: '/canvas/char_4.png'},
    char_5: {dir: '/canvas/char_5.png'},
    char_6: {dir: '/canvas/char_6.png'},
    char_7: {dir: '/canvas/char_7.png'},
    char_8: {dir: '/canvas/char_8.png'},
    char_9: {dir: '/canvas/char_9.png'},
  },
  sound: {},
};

export const threeOpt = {
  scene: {
    size: {
      width: 190
    }
  },
  camera: {
    fov: 45, // degree, vertical field of view
    vec: {
      x: 0,
      y: 0,
      z: 1,
    },
    visible: {
      near: 0.9, // %, ratio to camera distance
      far: 5 // %, ratio to camera distance
    }
  },
  ptc: {
    num: 14284,
    size: 0.018,
    mobileSizeCoe: 3, // %, ratio to text size, coefficent for particle size (mobile only)
    mobileNumCoe: 0.25, // %, ratio to text size, coefficent for particle number(density) (mobile only)
    color: "rgb(255, 255, 255)",
    speed: {
      normal: 0.01,
      morph: 0.18
    }
  },
  text: {
    size: 0.11, // %, ratio to scene width, additional affection to each text by dom dataset
    mobileSizeCoe: 1.15, // %, ratio to text size, coefficent for text size (mobile only)
    lineHeight: 1.3, // %, ratio to text size
    height: 0.1, // px, extrusion height of text geometry
    curveSegments: 10, // number, detail factor for text geometry
    translate: {
      y: 0.0, // %, ratio to height of three scene
    }
  },
  morph: {
    time: {
      color: 2,
      speed: 0.1,
      move: 2
    },
  },
  push: {
    limitPerWidth: 0.7,
    xFactor: {
      distCoe: 0.05,
      pow: 3
    },
    yFactor: {
      pow: 0.8,
      randomDom: 0.1,
      translate: 0.1
    }
  },
  pop: {
    homeForceScale: 0.006,
    cursorDist: 0.5,
    mobileDistCoe: 10,
    decelForce: 0.88
  },
  noise: {
    speed: 0.007,
    recoverTime: 20, // frame
    threshold: 0.6, // %
    power: {
      normal: 0.02,
      flee: 15
    }
  }
}