import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import CoverControls from '@/components/CoverControls';
import ContentIntroExhibition from '@/components/ContentIntroExhibition';
import ContentIntroWork from '@/components/ContentIntroWork';
import ContentPlaylist from '@/components/ContentPlaylist';
import CoverVideo from '@/components/CoverVideo';
import CoverCanvas from '@/components/CoverCanvas';
import CoverThumbnail from '@/components/CoverThumbnail';
import CoverGallery from '@/components/CoverGallery';
import { useUI } from '@/store/ui';
import { ArtworkItem, ArtworkData, ArtworkColorsText, ArtworkColorsReverse } from '@/data';

const resolveList = (list: ArtworkItem[]): ArtworkItem[] => {
  const last: any = [...list].pop();
  const [first, ...others] = [...list.slice(0, list.length - 1)];

  const shuffleList = [first, ...others.sort(() => 0.5 - Math.random()), last];

  const videoList = shuffleList.filter((o) => o.video !== null && o.video.time);
  const totalTime = videoList.map((o) => o.video.time).reduce((a, b) => a + b, 0);

  const separatedList = shuffleList.map((item) => {
    if (item?.video?.time) {
      item.percent = Math.round((item.video.time / totalTime) * 100);
    } else {
      item.percent = Math.round((1 / shuffleList.length) * 100);
    }
    return item;
  });

  return separatedList;
};

const Main = () => {
  const { isActiveCanvas, showCanvas, hideCanvas, setActiveLabelIndex, activeCanvasLabelIndex, player, activeGalleryIndex, setGalleryIndex } =
    useUI();
  const [isShowDetail, setShowDetail] = useState(false);
  const [list, setList] = useState<ArtworkItem[]>(resolveList(ArtworkData));
  const [activeItem, setActiveItem] = useState<ArtworkItem>(list[0]);
  const [videoPercent, setVideoPercent] = useState(0);
  const [isVideoPlay, setVideoPlay] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const isMainCover = useMemo(() => activeItem.name === null, [activeItem]);
  const activeIndex = useMemo(() => list.findIndex((o) => o.title === activeItem.title), [list, activeItem]);
  const prevItem = useMemo(() => (activeIndex === 0 ? list[list.length - 1] : list[activeIndex - 1]), [list, activeIndex]);
  const nextItem = useMemo(() => (list.length - 1 === activeIndex ? list[0] : list[activeIndex + 1]), [list, activeIndex]);
  const canvasLabel = useMemo(() => activeItem.canvas.labels[activeCanvasLabelIndex], [activeCanvasLabelIndex, activeItem]);
  const isGalleryType = useMemo(() => activeItem.video === null && !!activeItem.image && activeItem.image?.imageList.length > 0, [activeItem]);

  const isPreventHideControls = useMemo(() => {
    if (isShowDetail) return true;
    if (isGalleryType) return activeGalleryIndex === -1;
    if (!isVideoPlay) return true;
    return false;
  }, [isShowDetail, isGalleryType, isVideoPlay, activeGalleryIndex]);

  useEffect(() => {
    if (isShowDetail) {
      window.history.pushState(null, document.title, window.location.href);
      window.onpopstate = function () {
        setShowDetail(false);
      };
    } else {
      onAfterClose();
    }
    function onAfterClose() {
      window.onpopstate = null;
    }
  }, [isShowDetail]);

  useEffect(() => {
    const el = contentRef?.current;
    if (el) setTimeout(() => el.scrollTo(0, 0), 3000);
  }, [contentRef, activeItem]);

  const playVideo = useCallback(() => {
    if (player) {
      player.play().then(() => {
        setVideoPlay(true);
        hideCanvas();
      });
    }
  }, [player, hideCanvas]);

  const pauseVideo = useCallback(() => {
    if (player) player.pause();
    setVideoPlay(false);
    showCanvas();
  }, [player, showCanvas]);

  const handleClickMenu = useCallback(() => {
    const next = !isShowDetail;
    setShowDetail(next);
    setGalleryIndex(-1);
    showCanvas();
    pauseVideo();
  }, [isShowDetail, showCanvas, pauseVideo, setGalleryIndex]);

  const handleActiveItem = useCallback(
    (item: ArtworkItem) => {
      showCanvas();
      setVideoPercent(0);
      setActiveItem(item);
      setActiveLabelIndex(0);
      setShowDetail(false);
    },
    [showCanvas, setActiveLabelIndex],
  );

  const handleChangeCanvas = useCallback(
    (index = 0) => {
      setActiveLabelIndex(index);
    },
    [setActiveLabelIndex],
  );

  const handleVideoPlay = useCallback(() => {
    hideCanvas();
    playVideo();
  }, [hideCanvas, playVideo]);

  const onVideoPlaying = useCallback(() => {
    setVideoPlay(true);
    hideCanvas();
  }, [hideCanvas]);

  const onVideoPause = useCallback(() => {
    setVideoPlay(false);
    showCanvas();
  }, [showCanvas]);

  const handleClickPushedCanvas = useCallback(() => {
    if (isShowDetail) setShowDetail(false);
  }, [isShowDetail]);

  const onPageMove = useCallback(() => {
    pauseVideo();
    showCanvas();
  }, [pauseVideo, showCanvas]);

  return (
    <div className="flex flex-col w-full text-sm lg:text-base font-theme font-black text-gray-dark fixed top-0 right-0 bottom-0 left-0">
      <div
        className={`w-screen ${
          isShowDetail ? 'h-screen-2/5' : 'h-screen'
        } flex items-center relative transition-all duration-1000 select-none append-bg`}
        onClick={handleClickPushedCanvas}>
        <style>{`
          .append-bg::after {
            content: '';
            background-color: ${canvasLabel.color};
          }
          `}</style>
        <CoverControls
          list={list}
          activeItem={activeItem}
          activeIndex={activeIndex}
          prevItem={prevItem}
          nextItem={nextItem}
          onActiveItem={handleActiveItem}
          onClickMenu={handleClickMenu}
          isHideArrows={isShowDetail}
          isPreventHideControls={isPreventHideControls}
          isMainCover={isMainCover}
          videoPercent={videoPercent}
          setVideoPercent={setVideoPercent}
          isVideoPlay={isVideoPlay}
          onChangeCanvas={handleChangeCanvas}
          activeCanvasLabel={canvasLabel}
          isGalleryType={isGalleryType}
          onVideoPlay={handleVideoPlay}
          onPageMove={onPageMove}
          isShowDetail={isShowDetail}
        />

        <CoverGallery activeItem={activeItem} isShowDetail={isShowDetail} />

        <CoverVideo
          activeItem={activeItem}
          nextItem={nextItem}
          onActiveItem={handleActiveItem}
          onUpdateVideoPercent={setVideoPercent}
          onVideoPlay={onVideoPlaying}
          onVideoPause={onVideoPause}
          visible={!isActiveCanvas}
        />

        <CoverCanvas
          activeItem={activeItem}
          visible={isActiveCanvas}
          onActiveItem={handleActiveItem}
          isVideoPlay={isVideoPlay}
          isShowDetail={isShowDetail}
          activeCanvasLabel={canvasLabel}
          list={ArtworkData}
          isGalleryType={isGalleryType}
        />

        <CoverThumbnail activeItem={activeItem} fixedList={ArtworkData} visible={!isVideoPlay && !isGalleryType} />
      </div>
      <Transition timeout={500} in={isShowDetail}>
        {(state) => {
          const TransitionStyles: { [key: string]: CSSProperties } = {
            entering: { height: '60vh', opacity: 1 },
            entered: { height: '60vh', opacity: 1 },
            exiting: { height: 0, opacity: 1, display: 'none' },
            exited: { height: 0, opacity: 1, display: 'none' },
          };
          return (
            <div
              ref={contentRef}
              className={`mt-auto overflow-y-scroll z-50 relative transition-height duration-500`}
              style={{ ...TransitionStyles[state], backgroundColor: canvasLabel.color }}>
              <div
                className="flex flex-col lg:flex-row justify-between pb-10 lg:pt-8"
                style={{ backgroundColor: canvasLabel.color, color: ArtworkColorsText[canvasLabel.color] }}>
                {isMainCover ? (
                  <ContentIntroExhibition color={ArtworkColorsReverse[canvasLabel.color]} isShowDetail={isShowDetail} />
                ) : (
                  <ContentIntroWork activeItem={activeItem} color={ArtworkColorsText[canvasLabel.color]} isShowDetail={isShowDetail} />
                )}
                <ContentPlaylist
                  list={list}
                  onClickItem={handleActiveItem}
                  onUpdateList={setList}
                  color={canvasLabel.color}
                  activeItem={activeItem}
                />
              </div>
            </div>
          );
        }}
      </Transition>
    </div>
  );
};

export default Main;
