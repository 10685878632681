import { ArtworkColorsReverse, ArtworkColorsText } from '@/data';

const IconDrag: React.FC<{ color: string }> = ({ color }) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" id="iconDrag">
    <style>{`
      #iconDrag path {
        fill: ${ArtworkColorsText[color]};
        stroke: ${ArtworkColorsText[color]};
      }
      #iconDrag:hover path {
        fill: ${ArtworkColorsReverse[color]};
        stroke: ${ArtworkColorsReverse[color]};
      }
      .sorting #iconDrag path {
        fill: ${ArtworkColorsReverse[color]};
        stroke: ${ArtworkColorsReverse[color]};
      }
    `}</style>
    <path d="M6.5 16.5V14.5H29.5V16.5H6.5ZM29.5 20.5V22.5H6.5V20.5H29.5Z" />
  </svg>
);

export default IconDrag;
