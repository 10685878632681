import { Transition } from 'react-transition-group';
import { ArtworkItem } from '@/data';
import React, { CSSProperties, useEffect, useMemo } from 'react';
import { useUI } from '@/store/ui';

interface Props {
  activeItem: ArtworkItem;
  isShowDetail: boolean;
}

const CoverGallery: React.FC<Props> = ({ activeItem, isShowDetail }) => {
  const { activeGalleryIndex, setGalleryIndex } = useUI();

  const visible = useMemo(() => activeItem.video === null && !!activeItem.image && activeItem.image?.imageList.length > 0, [activeItem]);

  useEffect(() => {
    if (visible) {
      setGalleryIndex(-1);
    }
    return () => {
      setGalleryIndex(-1);
    };
  }, [visible, setGalleryIndex]);

  useEffect(() => {
    if (activeItem) setGalleryIndex(-1);
  }, [activeItem, setGalleryIndex]);

  return (
    <Transition timeout={1000} in={visible}>
      {(state) => {
        const TransitionCommonClassName = `transition-opacity duration-1000`;
        const TransitionCommonClassName2 = `${TransitionCommonClassName} ${isShowDetail ? 'invisible' : ''}`;
        const TransitionStyles: { [key: string]: CSSProperties } = {
          entering: { opacity: 1 },
          entered: { opacity: 1 },
          exiting: { opacity: 0 },
          exited: { opacity: 0 },
        };
        const TransitionStylesHide: { [key: string]: CSSProperties } = {
          entering: { opacity: 0 },
          entered: { opacity: 0 },
          exiting: { opacity: 1 },
          exited: { opacity: 1 },
        };
        return (
          <div
            className={`absolute top-0 right-0 bottom-0 left-0 h-full ${TransitionCommonClassName}`}
            data-type="gallery"
            style={TransitionStyles[state]}>
            {activeItem.image &&
              activeItem.image?.imageList.map((img, i) => {
                return (
                  <div
                    key={i}
                    className={`container m-auto mt-10 absolute top-0 right-0 bottom-0 left-0 bg-contain bg-no-repeat bg-center h-5/6 z-30 ${TransitionCommonClassName2}`}
                    style={{
                      backgroundImage: `url(${img})`,
                      ...(activeGalleryIndex === i ? TransitionStyles[state] : TransitionStylesHide[state]),
                    }}
                  />
                );
              })}
            <div
              className={`pointer-events-none absolute top-0 right-0 bottom-0 left-0 bg-cover	bg-fixed bg-no-repeat bg-center z-30 ${TransitionCommonClassName}`}
              style={{
                backgroundImage: `url(${activeItem.thumbnail})`,
                ...(activeGalleryIndex === -1 || isShowDetail ? TransitionStyles[state] : TransitionStylesHide[state]),
              }}
            />
            <div className={`pointer-events-none absolute top-0 right-0 bottom-0 left-0 bg-black z-20`} />
          </div>
        );
      }}
    </Transition>
  );
};

export default CoverGallery;
