import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const PORTAL_ID = 'portal';

const Portal: React.FC = ({ children }) => {
  const [el, setEl] = useState<Element>();

  useEffect(() => {
    const portalEl = document.getElementById(PORTAL_ID);
    if (portalEl) {
      setEl(portalEl);
    } else {
      const tempEl = document.createElement('div');
      tempEl.id = PORTAL_ID;
      document.body.appendChild(tempEl);
      setEl(tempEl);
    }
  }, []);

  if (!el) return null;

  return ReactDOM.createPortal(children, el);
};

export default Portal;
