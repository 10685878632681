import { Transition } from 'react-transition-group';
import { ArtworkItem } from '@/data';
import { CSSProperties } from 'react';

interface Props {
  activeItem: ArtworkItem;
  fixedList: ArtworkItem[];
  visible: boolean;
}

const CoverThumbnail: React.FC<Props> = ({ activeItem, fixedList, visible }) => {
  return (
    <Transition timeout={1000} in={visible}>
      {(state) => {
        const TransitionCommonClassName = 'transition-opacity duration-3000 ';
        const TransitionStylesHide: { [key: string]: CSSProperties } = {
          entering: { opacity: 1 },
          entered: { opacity: 1 },
          exiting: { opacity: 0 },
          exited: { opacity: 0 },
        };
        return (
          <div className={`pointer-events-none absolute top-0 right-0 bottom-0 left-0 z-30`} data-type="image">
            {fixedList.map((item, i) => {
              const isItemVisible = activeItem.title === item.title;
              return (
                <div
                  key={i}
                  className={`absolute top-0 right-0 bottom-0 left-0 bg-cover	bg-fixed bg-no-repeat bg-center z-30 ${TransitionCommonClassName}`}
                  style={{ backgroundImage: `url(${item.thumbnail})`, ...(isItemVisible ? TransitionStylesHide[state] : { opacity: 0 }) }}
                />
              );
            })}
          </div>
        );
      }}
    </Transition>
  );
};

export default CoverThumbnail;
